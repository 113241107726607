import React from 'react';
import styled from 'styled-components';
import { FaKeyboard, FaMicrophone } from 'react-icons/fa';

const InterviewTypeSelection = ({ onSelect, selectedType }) => {
  return (
    <SelectionContainer>
      <Title>면접 유형 선택</Title>
      <OptionContainer>
        <Option 
          onClick={() => onSelect('text')}
          selected={selectedType === 'text'}
        >
          <IconWrapper>
            <FaKeyboard size={24} />
          </IconWrapper>
          <OptionTitle>텍스트 모의면접</OptionTitle>
          <Description>
            텍스트로 질문과 답변을 주고받는 면접을 진행합니다.
            답변을 신중하게 작성할 수 있습니다.
          </Description>
        </Option>
        
        <Option 
          disabled={true}
          selected={false}
          style={{ pointerEvents: 'none' }}
        >
          <IconWrapper>
            <FaMicrophone size={24} />
          </IconWrapper>
          <OptionTitle>
            음성 모의면접
            <ComingSoonBadge>Coming Soon</ComingSoonBadge>
          </OptionTitle>
          <Description>
            실제 면접과 유사한 음성 기반 면접을 진행합니다.
            면접관의 음성 질문에 음성으로 답변합니다.
          </Description>
        </Option>
      </OptionContainer>
    </SelectionContainer>
  );
};

const SelectionContainer = styled.div`
  padding: 2rem;
  margin: 2rem 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

const Option = styled.div`
  flex: 1;
  padding: 2rem;
  border-radius: 12px;
  border: 2px solid ${props => props.selected ? '#2E74FF' : '#e0e0e0'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.6 : 1};
  background: ${props => props.disabled ? '#f5f5f5' : '#fff'};
  transition: all 0.3s ease;

  &:hover {
    transform: ${props => props.disabled ? 'none' : 'translateY(-5px)'};
    box-shadow: ${props => props.disabled ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.1)'};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  color: #007bff;
`;

const OptionTitle = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
`;

const Description = styled.p`
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const ComingSoonBadge = styled.span`
  background: #FF6B6B;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-left: 8px;
`;

export default InterviewTypeSelection; 