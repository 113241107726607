import React, { useEffect, useState } from 'react';
import '../styles/components/Experience.css';

const Experience = ({ data = [], onChange }) => {
  const [isNewGraduate, setIsNewGraduate] = useState(data.length === 0);

  useEffect(() => {
    if (data.length === 0) {
      setIsNewGraduate(true);
    }
  }, []);

  const handleAddExperience = () => {
    onChange([
      ...data,
      {
        companyName: '',
        position: '',
        startDate: '',
        endDate: '',
        description: ''
      }
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newData = data.filter((_, i) => i !== index);
    onChange(newData);
  };

  const handleExperienceChange = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    onChange(newData);
  };

  const handleDescriptionChange = (experienceIndex, descIndex, value) => {
    const newData = [...data];
    newData[experienceIndex].description = value;
    onChange(newData);
  };

  return (
    <div className="experience-section">
      <div className="career-selector">
        <h3>경력 사항</h3>
        <p className="career-subtitle">경력 여부를 선택해주세요.</p>
        <div className="career-type-buttons">
          <button
            className={`career-type-btn ${isNewGraduate ? 'active' : ''}`}
            onClick={() => {
              setIsNewGraduate(true);
              onChange([]);
            }}
          >
            <span className="career-icon">🎓</span>
            <span className="career-label">신입</span>
            <span className="career-desc">첫 취업 준비중이에요</span>
          </button>
          <button
            className={`career-type-btn ${!isNewGraduate ? 'active' : ''}`}
            onClick={() => {
              setIsNewGraduate(false);
              onChange([{
                companyName: '',
                position: '',
                startDate: '',
                endDate: '',
                description: ''
              }]);
            }}
          >
            <span className="career-icon">💼</span>
            <span className="career-label">경력</span>
            <span className="career-desc">업무 경험이 있어요</span>
          </button>
        </div>
      </div>

      {!isNewGraduate && (
        <div className="experience-list">
          {data.map((experience, index) => (
            <div key={index} className="experience-item">
              <div className="experience-item-header">
                <h3 className="experience-item-title">경력 사항</h3>
                <p className="experience-item-subtitle">근무하신 회사와 직책에 대한 정보를 입력해주세요.</p>
              </div>
              
              <div className="experience-header">
                <div className="company-info">
                  <input
                    type="text"
                    className="company-name-input"
                    placeholder="회사명"
                    value={experience.companyName}
                    onChange={(e) => handleExperienceChange(index, 'companyName', e.target.value)}
                  />
                  <input
                    type="text"
                    className="position-input"
                    placeholder="직책"
                    value={experience.position}
                    onChange={(e) => handleExperienceChange(index, 'position', e.target.value)}
                  />
                </div>
                <button
                  className="remove-experience-btn"
                  onClick={() => handleRemoveExperience(index)}
                >
                  삭제
                </button>
              </div>

              <div className="date-range">
                <input
                  type="date"
                  className="date-input"
                  value={experience.startDate}
                  onChange={(e) => handleExperienceChange(index, 'startDate', e.target.value)}
                />
                <span>~</span>
                <input
                  type="date"
                  className="date-input"
                  value={experience.endDate}
                  onChange={(e) => handleExperienceChange(index, 'endDate', e.target.value)}
                />
              </div>

              <div className="description-container">
                <textarea
                  className="description-input"
                  placeholder="주요 업무 및 성과를 입력하세요"
                  value={experience.description || ''}
                  onChange={(e) => handleDescriptionChange(index, null, e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="add-experience-wrapper">
        <button className="add-experience-btn" onClick={handleAddExperience}>
          +
        </button>
      </div>
    </div>
  );
};

export default Experience; 