import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TypingFeedback = ({ feedback, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  
  useEffect(() => {
    if (!feedback) return;
    
    setDisplayedText('');
    setIsComplete(false);
    let currentIndex = 0;
    
    const typingInterval = setInterval(() => {
      if (currentIndex < feedback.length) {
        setDisplayedText(prev => prev + feedback[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setIsComplete(true);
        onComplete?.(); // 타이핑이 완료되면 콜백 실행
      }
    }, 30); // 타이핑 속도를 좀 더 빠르게 조정

    return () => clearInterval(typingInterval);
  }, [feedback, onComplete]);

  return (
    <FeedbackContainer>
      <FeedbackText isComplete={isComplete}>
        <strong>피드백:</strong><br/>
        {displayedText}
        {!isComplete && <Cursor>|</Cursor>}
      </FeedbackText>
    </FeedbackContainer>
  );
};

const FeedbackContainer = styled.div`
  background: #fff3e0;
  padding: 1rem;
  border-radius: 8px;
  border-left: 4px solid #ff9800;
  margin: 1rem 0;
`;

const FeedbackText = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;
  color: #333;
  white-space: pre-wrap;
`;

const Cursor = styled.span`
  animation: blink 1s infinite;
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
`;

export default TypingFeedback; 