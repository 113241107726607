import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import './ProfileTab.css';
import UserDetailModal from '../UserDetailModal';
import UserDetailEdit from '../UserDetailEdit';
import { FaFileAlt, FaCamera, FaQuestionCircle, FaUserTie, FaCode, FaClipboardCheck, FaRegFileAlt, FaEnvelope, FaUser, FaArrowRight } from 'react-icons/fa';

// 이미지 import
import photo1 from '../../assets/images/id-photos/photo1.png';
import photo2 from '../../assets/images/id-photos/photo2.png';
import photo3 from '../../assets/images/id-photos/photo3.png';
import photo4 from '../../assets/images/id-photos/photo4.png';
import tokenIcon from '../../assets/images/icons/token-icon.png';
import subscriptionIcon from '../../assets/images/icons/subscription-icon.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProfileTab = ({ userData, setUserData }) => {
  const [listCounts, setListCounts] = useState({
    resumeCount: 0,
    photoCount: 0,
    questionCount: 0,
    interviewCount: 0,
    techTreeCount: 0,
    resumeEvaluationCount: 0,
    subscribePlan: 'Free',  // 기본값을 'Free'로 설정
  });

  const [showModal, setShowModal] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isDetailFilled, setIsDetailFilled] = useState(false);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDetailEdit, setShowDetailEdit] = useState(false);

  const [resumes, setResumes] = useState([]);

  const [showPhotoSelector, setShowPhotoSelector] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(2); // 기본값으로 photo2 설정
  const [generatedPhotos] = useState([
    {
      id: 1,
      imageUrl: photo1
    },
    {
      id: 2,
      imageUrl: photo2,
      isDefault: true
    },
    {
      id: 3,
      imageUrl: photo3
    },
    {
      id: 4,
      imageUrl: photo4
    }
  ]);

  // 버로부터 목록 개수 가 함수
  const fetchListCounts = async () => {
    const token = getToken();
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('받아온 목록 개수 전체:', data);
        console.log('자기소개서 평가 개수:', data.resumeEvaluationsCount);
        
        setListCounts({
          resumeCount: data.coverLettersCount,
          photoCount: data.idPhotosCount,
          questionCount: data.interviewQuestionsCount,
          interviewCount: 0,
          techTreeCount: data.techTreesCount,
          resumeEvaluationCount: data.resumeEvaluationsCount,
          subscribePlan: data.subscribePlan,
        });
      } else {
        console.error('Failed to fetch list counts');
      }
    } catch (error) {
      console.error('Error fetching list counts:', error);
    }
  };

  const fetchUserDetail = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BACKEND_URL}/user/detail`, {
        headers: { 'x-auth-token': token }
      });
      
      // 서버 응답에서 userDetail이 존재하고 비어있지 않은 경우
      if (response.data.userDetail && Object.keys(response.data.userDetail).length > 0) {
        setUserDetail(response.data.userDetail);
        setIsDetailFilled(true);
      } else {
        setUserDetail(null);
        setIsDetailFilled(false);
      }
    } catch (error) {
      console.error('Error fetching user detail:', error);
      setUserDetail(null);
      setIsDetailFilled(false);
    }
  };

  // 이력서 목록을 가져오는 함수
  const fetchResumes = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BACKEND_URL}/resume/list`, {
        headers: { 'x-auth-token': token }
      });
      setResumes(response.data);
      setListCounts(prev => ({
        ...prev,
        builderResumeCount: response.data.length
      }));
    } catch (error) {
      console.error('Error fetching resumes:', error);
    }
  };

  useEffect(() => {
    fetchListCounts();
    fetchUserDetail();
    fetchResumes();
  }, []);

  useEffect(() => {
    if (!userData.profileImage) {
      const defaultPhoto = generatedPhotos.find(photo => photo.isDefault);
      if (defaultPhoto) {
        handlePhotoSelect(defaultPhoto);
      }
    }
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchUserDetail(); // 모달이 닫힐 때 사용자 정보를 다시 불러옵니다.
  };

  const handleOpenDetailModal = () => {
    setShowDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    fetchUserDetail();
  };

  const handleOpenDetailEdit = () => {
    setShowDetailEdit(true);
  };

  const handleCloseDetailEdit = () => {
    setShowDetailEdit(false);
    fetchUserDetail();
  };

  const handleSaveUserDetail = (updatedUserDetail) => {
    setUserDetail(updatedUserDetail);
    setIsDetailFilled(true);
    // fetchUserDetail(); // 이 줄은 제거하거나 주석 처리
  };

  const handlePhotoSelect = async (photo) => {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/user/profile-image`,
        { imageUrl: photo.imageUrl },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': getToken()
          }
        }
      );

      if (response.status === 200) {
        setSelectedPhoto(photo.id);
        setUserData(prev => ({
          ...prev,
          profileImage: photo.imageUrl
        }));
        setShowPhotoSelector(false);
      }
    } catch (error) {
      console.error('프로필 사진 업데이트 실패:', error);
      alert('프로필 사진 업데이트에 실패했습니다.');
    }
  };

  const renderUserDetail = () => {
    if (!userDetail || !isDetailFilled) {
      return (
        <div className="user-detail-empty">
          <p>아직 상세 정보가 입력되지 않았습니다.</p>
          <button onClick={handleOpenDetailModal} className="edit-button">
            정보 입력하기
          </button>
        </div>
      );
    }

    return (
      <div className="user-detail-section">
        <div className="user-detail-header">
          <h2>상세 정보</h2>
          <button onClick={handleOpenDetailEdit} className="edit-button">
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
            </svg>
            수정하기
          </button>
        </div>

        <div className="detail-category">
          <h3 className="category-title">기본 정보</h3>
          <div className="detail-grid">
            <DetailItem label="이름" value={userDetail.name} />
            <DetailItem label="생년월일" value={userDetail.birthDate} />
            <DetailItem label="성별" value={userDetail.gender} />
            <DetailItem label="거주지역" value={userDetail.location} />
          </div>
        </div>

        <div className="detail-category">
          <h3 className="category-title">학력 정보</h3>
          <div className="detail-grid">
            <DetailItem label="최종학력" value={userDetail.education} />
            <DetailItem label="전공" value={userDetail.major} />
            {userDetail.isNewGraduate && (
              <>
                <DetailItem label="졸업연도" value={userDetail.graduationYear} />
                <DetailItem label="학점" value={userDetail.gpa} />
              </>
            )}
          </div>
        </div>

        <div className="detail-category">
          <h3 className="category-title">경력 사항</h3>
          <div className="detail-grid">
            <DetailItem label="경력 연수" value={userDetail.yearsOfExperience} />
            <DetailItem label="최근 직무" value={userDetail.recentPosition} />
            <DetailItem label="최근 근무지" value={userDetail.recentCompany} />
            <DetailItem label="주요 경력" value={userDetail.careerHighlights?.join(', ')} />
          </div>
        </div>

        <div className="detail-category">
          <h3 className="category-title">희망 근무 조건</h3>
          <div className="detail-grid">
            <DetailItem 
              label="희망 연봉" 
              value={`${userDetail.minSalary?.toLocaleString()}원 ~ ${userDetail.maxSalary?.toLocaleString()}원`} 
            />
            <DetailItem label="근무 시작 가능일" value={new Date(userDetail.startDate).toLocaleDateString()} />
            <DetailItem label="근무 가능 시간대" value={userDetail.workHours} />
            <DetailItem label="유연 근무제" value={userDetail.remoteWork} />
          </div>
        </div>

        <div className="detail-category">
          <h3 className="category-title">보유 스킬 및 자격</h3>
          <div className="detail-grid">
            <DetailItem label="보유 기술" value={userDetail.skills?.join(', ')} />
            <DetailItem label="자격증" value={userDetail.certifications?.join(', ')} />
            <DetailItem label="포트폴리오" value={userDetail.portfolioLinks?.join(', ')} />
          </div>
        </div>
      </div>
    );
  };

  const DetailItem = ({ label, value }) => (
    <div className="detail-item">
      <span className="detail-label">{label}:</span>
      <span className="detail-value">{value || '정보 없음'}</span>
    </div>
  );

  return (
    <div className="profile-tab">
      <div className="user-section">
        <div className="user-info">
          <div 
            className="profile-photo-container" 
            onClick={() => setShowPhotoSelector(true)}
          >
            <img 
              src={userData.profileImage || '/src/assets/images/id-photos/photo2.jpg'} 
              alt="프로필 사진" 
            />
            <div className="photo-edit-overlay">
              <span>수정</span>
            </div>
          </div>
          <div className="user-details">
            <h2>{userData.username} 님</h2>
            <p className="user-email"><FaEnvelope className="detail-icon" /> {userData.email}</p>
            <p className="user-nickname"><FaUser className="detail-icon" /> {userData.nickname}</p>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div className="user-stats">
          <Link to="/payment" className="stat-card token-card">
            <div className="stat-icon-container">
              <img src={tokenIcon} alt="Token" className="stat-icon" />
            </div>
            <div className="stat-details">
              <span className="stat-label">현재 보유한 토큰</span>
              <span className="stat-value">{userData.tokens}</span>
              <span className="stat-action">충전하기 <FaArrowRight className="arrow-icon" /></span>
            </div>
          </Link>
          <Link to="/payment" className="stat-card subscription-card">
            <div className="stat-icon-container">
              <img src={subscriptionIcon} alt="Subscription" className="stat-icon" />
            </div>
            <div className="stat-details">
              <span className="stat-label">구독 상태</span>
              <span className="stat-value">{listCounts.subscribePlan}</span>
              <span className="stat-action">구독하기 <FaArrowRight className="arrow-icon" /></span>
            </div>
          </Link>
        </div>
      </div>
      
      <div className="saved-files-section">
        <div className="saved-files-header">
          <h3>저장한 파일</h3>
        </div>
        <div className="saved-files-grid">
          <Link to="/mypage/saved-builder-resumes" className="file-card">
            <div className="file-icon">
              <FaRegFileAlt />
            </div>
            <div className="file-info">
              <span className="file-label">이력서 생성기</span>
              <span className="file-description">AI로 이력서를 작성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-resumes" className="file-card">
            <div className="file-icon">
              <FaFileAlt />
            </div>
            <div className="file-info">
              <span className="file-label">자소서 생성기</span>
              <span className="file-description">AI로 자기소개서를 작성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-photos" className="file-card">
            <div className="file-icon">
              <FaCamera />
            </div>
            <div className="file-info">
              <span className="file-label">증명사진 생성기</span>
              <span className="file-description">AI로 증명사진을 생성해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-questions" className="file-card">
            <div className="file-icon">
              <FaQuestionCircle />
            </div>
            <div className="file-info">
              <span className="file-label">면접예상질문 생성기</span>
              <span className="file-description">AI로 예상 면접 질문을 준비하세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-interviews" className="file-card">
            <div className="file-icon">
              <FaUserTie />
            </div>
            <div className="file-info">
              <span className="file-label">AI 면접 기록</span>
              <span className="file-description">AI와 면접 연습을 해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-tech-trees" className="file-card">
            <div className="file-icon">
              <FaCode />
            </div>
            <div className="file-info">
              <span className="file-label">테크트리 생성기</span>
              <span className="file-description">AI로 기술 스택을 분석해보세요</span>
            </div>
          </Link>
          
          <Link to="/mypage/saved-resume-evaluations" className="file-card">
            <div className="file-icon">
              <FaClipboardCheck />
            </div>
            <div className="file-info">
              <span className="file-label">자기소개서 평가</span>
              <span className="file-description">AI로 자기소개서를 평가받아보세요</span>
            </div>
          </Link>
        </div>
      </div>
      {showModal && (
        <UserDetailModal
          onClose={handleCloseModal}
          onSave={fetchUserDetail}
        />
      )}
      {showDetailModal && (
        <UserDetailModal
          onClose={handleCloseDetailModal}
          onSave={handleSaveUserDetail}
        />
      )}
      {showDetailEdit && (
        <UserDetailEdit
          isOpen={showDetailEdit}
          onClose={handleCloseDetailEdit}
          userDetail={userDetail}
          onSave={handleSaveUserDetail}
        />
      )}
      {showPhotoSelector && (
        <div className="photo-selector-modal">
          <div className="photo-selector-content">
            <button 
              className="close-button"
              onClick={() => setShowPhotoSelector(false)}
            >
              ×
            </button>
            <h3>프로필 사진 선택</h3>
            <div className="photo-grid">
              {generatedPhotos.map((photo) => (
                <div 
                  key={photo.id}
                  className={`photo-option ${selectedPhoto === photo.id ? 'selected' : ''}`}
                  onClick={() => handlePhotoSelect(photo)}
                >
                  <img src={photo.imageUrl} alt={`프로필 사진 옵션 ${photo.id}`} />
                </div>
              ))}
            </div>
            <div className="modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setShowPhotoSelector(false)}
              >
                취소
              </button>
              <button 
                className="select-button"
                onClick={() => handlePhotoSelect(selectedPhoto)}
              >
                선택
              </button>
            </div>
          </div>
        </div>
      )}
      {renderUserDetail()}
    </div>
  );
};

export default ProfileTab;
