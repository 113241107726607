import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import './SavedCommon.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedBuilderResumes = () => {
  const navigate = useNavigate();
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${BACKEND_URL}/resume/list`, {
          headers: { 'x-auth-token': token }
        });
        setResumes(response.data);
      } catch (error) {
        console.error('Error fetching resumes:', error);
        alert('이력서 목록을 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchResumes();
  }, []);

  const renderActions = (resume) => {
    if (resume.status === 'draft') {
      return (
        <>
          <button 
            className="action-button primary"
            onClick={() => navigate(`/tools/resume-builder/${resume._id}`)}
          >
            수정하기
          </button>
          <button 
            className="delete-button"
            onClick={async (e) => {
              e.stopPropagation();
              if (window.confirm('정말 삭제하시겠습니까?')) {
                try {
                  const token = getToken();
                  await axios.delete(`${BACKEND_URL}/resume/${resume._id}`, {
                    headers: { 'x-auth-token': token }
                  });
                  setResumes(resumes.filter(r => r._id !== resume._id));
                  alert('이력서가 삭제되었습니다.');
                } catch (error) {
                  alert('삭제 중 오류가 발생했습니다.');
                }
              }
            }}
          >
            삭제
          </button>
        </>
      );
    } else {
      return (
        <>
          <button 
            className="action-button primary"
            onClick={() => navigate(`/tools/resume-builder/view/${resume._id}`)}
          >
            보기
          </button>

          <button 
            className="delete-button"
            onClick={async (e) => {
              e.stopPropagation();
              if (window.confirm('정말 삭제하시겠습니까?')) {
                try {
                  const token = getToken();
                  await axios.delete(`${BACKEND_URL}/resume/${resume._id}`, {
                    headers: { 'x-auth-token': token }
                  });
                  setResumes(resumes.filter(r => r._id !== resume._id));
                  alert('이력서가 삭제되었습니다.');
                } catch (error) {
                  alert('삭제 중 오류가 발생했습니다.');
                }
              }
            }}
          >
            삭제
          </button>
        </>
      );
    }
  };

  const handleDelete = async (resumeId) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        const token = getToken();
        await axios.delete(`${BACKEND_URL}/resume/${resumeId}`, {
          headers: { 'x-auth-token': token }
        });
        setResumes(resumes.filter(r => r._id !== resumeId));
        alert('이력서가 삭제되었습니다.');
      } catch (error) {
        alert('삭제 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="saved-container">
      <div className="saved-header">
        <Link to="/mypage" className="back-link">
          <FaArrowLeft className="back-icon" />
          <span>돌아가기</span>
        </Link>
        <button 
          className="action-button"
          onClick={() => navigate('/tools/resume-builder')}
          style={{ backgroundColor: '#4CAF50', color: 'white' }}
        >
          새 이력서 작성
        </button>
      </div>

      <h2>저장된 이력서</h2>

      {loading ? (
        <div className="loading-state">로딩 중...</div>
      ) : resumes.length === 0 ? (
        <div className="empty-state">
          <p>저장된 이력서가 없습니다.</p>
          <button 
            className="action-button"
            onClick={() => navigate('/tools/resume-builder')}
            style={{ backgroundColor: '#4CAF50', color: 'white' }}
          >
            이력서 작성하기
          </button>
        </div>
      ) : (
        <div className="item-grid">
          {resumes.map((resume) => (
            <div key={resume._id} className="item-card">
              <div className="card-header">
                <span className="card-title">
                  {resume.title || resume.resumeData?.basicInfo?.name || '무제'}
                </span>
                <span className={`status-badge ${resume.status}`}>
                  {resume.status === 'draft' ? '임시저장' : '저장됨'}
                </span>
              </div>
              <span className="card-date">
                최종 수정일: {new Date(resume.lastModified).toLocaleDateString()}
              </span>
              <div className="card-actions">
                {resume.status === 'draft' ? (
                  <button 
                    className="action-button primary"
                    onClick={() => navigate(`/tools/resume-builder/${resume._id}`)}
                  >
                    수정하기
                  </button>
                ) : (
                  <button 
                    className="action-button primary"
                    onClick={() => navigate(`/tools/resume-builder/view/${resume._id}`)}
                  >
                    보기
                  </button>
                )}
                <button 
                  className="action-button delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(resume._id);
                  }}
                >
                  삭제
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedBuilderResumes; 