import React from 'react';
import './StepProgress.css';

const StepProgress = ({ currentStep, steps }) => {
  return (
    <div className="step-progress-container">
      <div className="step-progress-wrapper">
        {steps.map((step, index) => (
          <div 
            key={index} 
            className={`step-item ${index + 1 <= currentStep ? 'active' : ''} ${index + 1 < currentStep ? 'completed' : ''}`}
          >
            <div className="step-circle">
              {index + 1 < currentStep ? (
                <svg className="check-icon" viewBox="0 0 24 24">
                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                </svg>
              ) : (
                <span className="step-number">{index + 1}</span>
              )}
            </div>
            <div className="step-info">
              <span className="step-label">{step}</span>
              <span className="step-status">
                {index + 1 < currentStep ? '완료' : 
                 index + 1 === currentStep ? '진행 중' : '대기 중'}
              </span>
            </div>
            {index < steps.length - 1 && <div className="step-connector" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepProgress;
