import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ResumeTemplate from './components/ResumeTemplate';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import './styles/ResumeBuilderView.css';
import { Document, Paragraph, TextRun } from 'docx';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResumeBuilderView = () => {
  const { resumeId } = useParams();
  const navigate = useNavigate();
  const [resumeData, setResumeData] = useState(null);
  const [template, setTemplate] = useState('modern');

  useEffect(() => {
    const fetchResume = async () => {
      try {
        const token = getToken();
        if (!token) {
          alert('로그인이 필요합니다.');
          navigate('/login');
          return;
        }

        const response = await axios.get(`${BACKEND_URL}/resume/${resumeId}`, {
          headers: { 'x-auth-token': token }
        });

        if (response.data && response.data.resumeData) {
          setResumeData(response.data.resumeData);
          setTemplate(response.data.template || 'modern');
        }
      } catch (error) {
        console.error('이력서 불러오기 실패:', error);
        alert('이력서를 불러오는데 실패했습니다.');
        navigate('/mypage/saved-builder-resumes');
      }
    };

    fetchResume();
  }, [resumeId, navigate]);

  const handleExportWord = () => {
    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          new Paragraph({
            children: [new TextRun({ text: resumeData.basicInfo.name + '의 이력서', bold: true, size: 30 })],
          }),
          new Paragraph({}),
          // 기본 정보
          ...Object.entries(resumeData.basicInfo).map(([key, value]) => 
            new Paragraph({
              children: [new TextRun({ text: `${key}: ${value}` })]
            })
          ),
        ],
      }],
    });
    
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'resume.docx');
    });
  };

  const handlePrint = () => {
    window.print();
  };

  if (!resumeData) return <div>로딩 중...</div>;

  return (
    <div className="resume-builder-view">
      <div className="view-header">
        <h2>{resumeData.basicInfo.name}님의 이력서</h2>
        <div className="view-actions">
          <button onClick={() => navigate('/mypage/saved-builder-resumes')}>
            목록으로
          </button>
          <button onClick={handleExportWord} className="export-button">
            워드로 내보내기
          </button>
          <button onClick={handlePrint} className="print-button">
            인쇄하기 / PDF 저장
          </button>
        </div>
      </div>
      <div className="view-content">
        <ResumeTemplate type={template} data={resumeData} />
      </div>
    </div>
  );
};

export default ResumeBuilderView; 