// pages/tools/interview/AIInterviewerPage.jsx
// ai 면접 페이지
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaUserTie, FaLaptopCode, FaComments, FaRobot } from 'react-icons/fa';
import InterviewQuestions from '../../../components/interview/InterviewQuestions';

const AIInterviewerPage = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({
    name: '',
    jobField: '',
    interviewType: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <PageContainer>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <FormContainer>
          <Header>
            <IconWrapper>
              <FaRobot size={40} color="#2E74FF" />
            </IconWrapper>
            <Title>AI 면접관</Title>
            <SubTitle>AI가 당신의 면접을 도와드립니다</SubTitle>
          </Header>

          <StepsContainer>
            {['기본 정보', '서류 제출', '면접 진행'].map((stepTitle, index) => (
              <React.Fragment key={index}>
                <StepIndicator active={step === index + 1} completed={step > index + 1}>
                  <StepNumber>{index + 1}</StepNumber>
                  <StepText>{stepTitle}</StepText>
                  {step > index + 1 && <CheckIcon>✓</CheckIcon>}
                </StepIndicator>
                {index < 2 && <StepConnector completed={step > index + 1} />}
              </React.Fragment>
            ))}
          </StepsContainer>

          {step === 1 ? (
            <FormSection>
              <InputGroup>
                <Label>
                  <FaUserTie />
                  이름
                </Label>
                <Input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleInputChange}
                  placeholder="이름을 입력하세요"
                />
              </InputGroup>
              
              <InputGroup>
                <Label>
                  <FaLaptopCode />
                  관심 직무
                </Label>
                <Select
                  name="jobField"
                  value={userData.jobField}
                  onChange={handleInputChange}
                >
                  <option value="">직무를 선택하세요</option>
                  <option value="frontend">프론트엔드 개발자</option>
                  <option value="backend">백엔드 개발자</option>
                  <option value="fullstack">풀스택 개발자</option>
                  <option value="design">UI/UX 디자이너</option>
                </Select>
              </InputGroup>

              <InputGroup>
                <Label>
                  <FaComments />
                  면접 유형
                </Label>
                <Select
                  name="interviewType"
                  value={userData.interviewType}
                  onChange={handleInputChange}
                >
                  <option value="">면접 유형을 선택하세요</option>
                  <option value="technical">기술 면접</option>
                  <option value="behavioral">인성 면접</option>
                  <option value="culture">컬쳐핏 면접</option>
                </Select>
              </InputGroup>

              <StartButton 
                onClick={() => setStep(2)}
                disabled={!userData.name || !userData.jobField || !userData.interviewType}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                면접 시작하기
              </StartButton>
            </FormSection>
          ) : (
            <InterviewQuestions 
              jobField={userData.jobField}
              interviewType={userData.interviewType}
              userName={userData.name}
            />
          )}
        </FormContainer>
      </motion.div>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f9ff 0%, #f0f4ff 100%);
  padding: 3rem 2rem;
`;

const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  padding: 3rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const IconWrapper = styled.div`
  background: #f0f4ff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #1a1f36;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.p`
  color: #4e5d78;
  font-size: 1.1rem;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  padding: 0 2rem;
`;

const StepIndicator = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  opacity: ${props => props.active ? 1 : props.completed ? 1 : 0.5};
  transition: all 0.3s ease;
`;

const StepNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.completed ? '#36B37E' : props.active ? '#2E74FF' : '#E5E9F2'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  transition: all 0.3s ease;
  
  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    background: ${props => props.active ? 'rgba(46, 116, 255, 0.1)' : 'transparent'};
    animation: ${props => props.active ? 'pulse 2s infinite' : 'none'};
  }
`;

const StepText = styled.span`
  font-size: 0.9rem;
  color: ${props => props.active ? '#2E74FF' : '#4E5D78'};
  font-weight: ${props => props.active ? '600' : '400'};
  white-space: nowrap;
`;

const StepConnector = styled.div`
  width: 100px;
  height: 2px;
  background: ${props => props.completed ? '#36B37E' : '#E5E9F2'};
  margin: 0 1rem;
  transition: all 0.3s ease;
  
  @media (max-width: 768px) {
    width: 50px;
  }
`;

const CheckIcon = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: #36B37E;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 2px solid white;
`;

const keyframes = {
  pulse: `
    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 0.8;
      }
      50% {
        transform: scale(1.1);
        opacity: 0.4;
      }
      100% {
        transform: scale(1);
        opacity: 0.8;
      }
    }
  `
};

const FormSection = styled.div`
  background: #f8faff;
  padding: 2rem;
  border-radius: 16px;
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #1a1f36;
  
  svg {
    color: #2E74FF;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
    box-shadow: 0 0 0 3px rgba(46, 116, 255, 0.1);
  }
`;

const StartButton = styled(motion.button)`
  width: 100%;
  padding: 1.2rem;
  background: #2E74FF;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease;

  &:disabled {
    background: #e0e0e0;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: #1b5fd9;
  }
`;

export default AIInterviewerPage;
