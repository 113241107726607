import React from 'react';
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";    // 토스페이먼츠 sdk
import { useEffect, useState } from "react";
import { CheckCircle } from 'lucide-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import { v4 as uuidv4 } from 'uuid';  // uuid 패키지
import credit_card from '../../assets/images/icons/credit-card.png';
import './PaymentPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 랜덤 20자리 문자열 생성 함수
const generateRandomString = (length = 20) => {
  // 조건에 맞는 문자 집합을 정의합니다.
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const specialChars = '-_=.@';

  // 각 문자 집합에서 최소 1개씩 선택해야 하므로 시작 부분에 추가
  let result = '';
  result += lowerCase.charAt(Math.floor(Math.random() * lowerCase.length));  // 소문자
  result += upperCase.charAt(Math.floor(Math.random() * upperCase.length));  // 대문자
  result += digits.charAt(Math.floor(Math.random() * digits.length));        // 숫자
  result += specialChars.charAt(Math.floor(Math.random() * specialChars.length)); // 특수문자

  // 나머지 문자는 랜덤하게 선택하여 추가
  const allChars = lowerCase + upperCase + digits + specialChars;
  for (let i = result.length; i < length; i++) {
    result += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // 결과 문자열이 주어진 길이보다 작다면 추가로 랜덤한 문자들을 넣음
  result = result.slice(0, length);

  // 문자열을 섞어서 반환 (문자열의 무작위성을 높이기 위해)
  return result.split('').sort(() => Math.random() - 0.5).join('');
};

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 전 페이지에서 선택한 구독정보를 받아오는 변수
  const { planTitle, price, tokens, features } = location.state || {}; // location.state로부터 값 가져오기
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);  // "등록 중" 상태 관리
  const [termsAgreed, setTermsAgreed] = useState(false); // 약관 동의 상태
  const [payment, setPayment] = useState(null);   // 결제 정보 변수
  const [billingInfo, setBillingInfo] = useState(null); // 빌링 정보 변수
  const [selectedBilling, setSelectedBilling] = useState(null); // To store selected billing info
  const [userInfo, setUserInfo] = useState(null); // 사용자 정보
  const [searchParams] = useSearchParams();   // 빌링 키 발급 성공 시 url 변수 값 받아오기
  const [isRedirected, setIsRedirected] = useState(false);  // URL 파라미터가 있는지 여부 확인
  const [isreload, setReload] = useState(false);
  const [randomKey, setRandomkey] = useState(generateRandomString(20));
  const token = getToken();

  // 🚀 **1. 구독 정보 저장 & 새로고침 대비**
  useEffect(() => {
    const savedInfo = sessionStorage.getItem("subscriptionInfo");

    if (location.state) {
      // ✅ location.state가 존재하면 해당 값 사용
      setSubscriptionInfo(location.state);
      sessionStorage.setItem("subscriptionInfo", JSON.stringify(location.state));
    } else if (savedInfo) {
      // ✅ 새로고침 시 sessionStorage에서 데이터 복구
      setSubscriptionInfo(JSON.parse(savedInfo));
    } else {
      alert("구독 정보를 찾을 수 없습니다.");
      navigate("/subscribe");
    }
  }, [location.state, navigate]);

  // ------ 사용자 정보 가져오기 ------
  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const response = await fetch(`${BACKEND_URL}/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'x-auth-token': token,
          },
        });

        const data = await response.json();
        if (data) {
          setUserInfo(data); // 사용자 정보 저장
        } else {
          alert("사용자 정보를 가져오는 데 실패했습니다.");
        }
      } catch (error) {
        console.error("사용자 정보 가져오기 오류:", error);
      }
    }

    if (token) {
      fetchUserInfo();
    }
  }, [token, isRedirected]);

  // 🚀 **3. 등록된 카드 정보 가져오기 & 자동 선택**
  const fetchBillingInfo = async () => {
    if (!token) return;
    try {
      const response = await fetch(`${BACKEND_URL}/billing/check-billing`, {
        method: "GET",
        headers: { "Content-Type": "application/json", "x-auth-token": token },
      });
      const data = await response.json();
      if (data.hasBillingInfo) {
        setBillingInfo(data.billingData);
        // ✅ 카드가 등록된 상태라면 자동으로 첫 번째 카드 선택
        if (!selectedBilling && data.billingData.length > 0) {
          setSelectedBilling(data.billingData[0]);
        }
      }
    } catch (error) {
      console.error("빌링 정보 확인 오류:", error);
    }
  };

  useEffect(() => {
    fetchBillingInfo();
  }, [token]);

  // 빌링 정보 자동 업데이트 기능
  useEffect(() => {
    if (billingInfo && billingInfo.length > 0) {
      console.log("🔄 빌링 정보 업데이트됨:", billingInfo);
      setSelectedBilling(billingInfo[0]);  // ✅ 가장 최신 카드 자동 선택
  
      // 🚀 구독 정보 업데이트 (빌링 정보 변경 시)
      const savedInfo = sessionStorage.getItem("subscriptionInfo");
      if (savedInfo) {
        setSubscriptionInfo(JSON.parse(savedInfo));
      }
    }
  }, [billingInfo]);

  // ------ ClientKey, CustomerKey 정의 ------
  const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;

  // 고객 키 설정 (selectedBilling이 있으면 해당 키, 없으면 randomKey 사용)
  const customerKey = selectedBilling?.customerKey || randomKey;
  // ✅ selectedBilling 변경 시 randomKey 재설정 로직 개선
  useEffect(() => {
    if (selectedBilling) {
      // 선택된 카드가 있을 경우, 해당 고객 키 사용
      setRandomkey(selectedBilling.customerKey);
    } else {
      // 선택된 카드가 없으면 기존 randomKey 유지
      setRandomkey((prevKey) => prevKey || generateRandomString(20));
    }
  }, [selectedBilling]);
  // TOSS SDK Reload 함수
  useEffect(() => {
    const reloadTossSDK = async () => {
      console.log(customerKey);
      if (!clientKey || !customerKey) {
        console.warn("⚠️ Toss SDK 초기화 중단 - 필요한 키 없음");
        return;
      }

      try {
        console.log("🔄 Toss SDK를 다시 로드합니다...");
        console.log("📌 사용되는 customerKey:", customerKey);

        const tossPayments = await loadTossPayments(clientKey);
        const payment = tossPayments.payment({ customerKey });

        setPayment(payment);
        console.log("✅ Toss SDK 로드 완료");
      } catch (error) {
        console.error("⚠️ Toss SDK 로드 실패:", error);
      }
    };

    reloadTossSDK();
  }, [selectedBilling, clientKey]); // ✅ 카드 변경될 때 SDK 로드

  // ------ '카드 등록하기' 버튼 누르면 결제창 띄우기 ------
  // @docs https://docs.tosspayments.com/sdk/v2/js#paymentrequestpayment
  async function requestBillingAuth() {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하기
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    try {
      // 결제를 요청하기 전에 orderId, amount를 서버에 저장하기
      await payment.requestBillingAuth({
        method: "CARD", // 자동결제(빌링)는 카드만 지원합니다
        successUrl: window.location.origin + "/payment/details", // 요청이 성공하면 리다이렉트되는 URL
        // /payment/details?customerKey={CUSTOMER_KEY}&authKey={AUTH_KEY}
        failUrl: window.location.origin + "/billing/fail", // 요청이 실패하면 리다이렉트되는 URL
        // /fail?code={ERROR_CODE}&message={ERROR_MESSAGE}
        customerEmail: userInfo.email,
        customerName: userInfo.username,
      });
    } catch (error) {
      // 결제 창이 닫혔을 때 발생한 오류를 처리
      if (error.message && error.message.includes("취소되었습니다")) {
        alert("카드 등록이 취소되었습니다.");
      } else {
        alert("카드 등록 과정에서 오류가 발생했습니다. 나중에 다시 시도해주세요.");
      }
    }
  }

  // 🚀 **4. 카드 등록 후 자동으로 정보 갱신**
  useEffect(() => {
    const customerKeyFromURL = searchParams.get("customerKey");
    const authKeyFromURL = searchParams.get("authKey");

    if (customerKeyFromURL && authKeyFromURL) {
      async function issueBillingKey() {
        try {
          const requestData = { customerKey: customerKeyFromURL, authKey: authKeyFromURL };
          await fetch(`${BACKEND_URL}/billing/issue-billing-key`, {
            method: "POST",
            headers: { "Content-Type": "application/json", "x-auth-token": token },
            body: JSON.stringify(requestData),
          });

          // ✅ 카드 등록 후 최신 빌링 정보 가져오기
          await fetchBillingInfo();

          setIsProcessing(false);
        } catch (err) {
          console.error("Fail to issue billing key", err);
        }
      }
      issueBillingKey();
    }
  }, [searchParams, token]);

  // 결제 요청 함수
  const handlePayment = async () => {
    try {
      if (!termsAgreed) {
        alert("개인정보 이용약관에 동의해주세요.");
        return;  // 약관에 동의하지 않으면 결제 진행 안함
      }
      
      console.log('pass');
      if (!selectedBilling || !selectedBilling.billingKey) {
        alert("결제할 카드를 선택해주세요.");
        // 🔥 빌링 정보 강제 업데이트
        await fetchBillingInfo();
        return;
      }

      // 🚀 subscriptionInfo가 undefined인 경우 sessionStorage에서 강제 로드
      if (!subscriptionInfo.price || !subscriptionInfo.planTitle) {
        const savedInfo = sessionStorage.getItem("subscriptionInfo");
        if (savedInfo) {
          setSubscriptionInfo(JSON.parse(savedInfo));
        }
        alert("구독 정보를 다시 불러오는 중입니다. 다시 시도해주세요.");
        return;
      }
      console.log(subscriptionInfo);

      const orderId = uuidv4();  // UUID로 고유한 주문 ID 생성
      const amount = subscriptionInfo.price; // 결제 금액
      const orderName = subscriptionInfo.planTitle;  // 주문 이름
      const customerEmail = userInfo.email;  // 고객 이메일
      const customerName = userInfo.username;  // 고객 이름
      const taxFreeAmount = 0;  // 면세 금액 (필요한 경우 수정)

      // 결제 요청 데이터
      const paymentData = {
        billingKey: selectedBilling.billingKey,  // 선택된 카드의 billingKey
        customerKey: selectedBilling.customerKey,  // 선택된 카드의 customerKey
        amount,
        orderId,
        orderName,
        customerEmail,
        customerName,
        taxFreeAmount,
      };
      console.log(paymentData);
      // 결제 요청을 백엔드로 보내기
      const response = await fetch(`${BACKEND_URL}/billing/pay`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'x-auth-token': token,  // 인증 토큰
        },
        body: JSON.stringify(paymentData),
      });

      const data = await response.json();
      
      if (response.ok) {
        console.log('결제 요청 성공:', data);
        alert("결제가 성공적으로 처리되었습니다.");
        // 결제 완료 후 리다이렉트나 추가 작업을 진행할 수 있습니다.
        // 현재 날짜와 1달 뒤 날짜 계산
        const today = new Date();
        const nextMonth = new Date();
        nextMonth.setMonth(today.getMonth() + 1);

        // 결제 성공 페이지로 상태와 함께 이동
        navigate('/payment/success', {
          state: {
            price: amount,
            planTitle: orderName,
            tokens: subscriptionInfo.tokens,
            features: subscriptionInfo.features,
            today: today.toISOString().split('T')[0],  // YYYY-MM-DD 형식
            nextPaymentDate: nextMonth.toISOString().split('T')[0],  // 다음 결제일
          },
        });
      } else {
        console.error('결제 요청 실패:', data);
        alert("결제 요청에 실패했습니다.");
        navigate('/payment/fail');
      }
    } catch (error) {
      console.error("결제 요청 중 오류 발생:", error);
      alert("서버 오류가 발생했습니다. 나중에 다시 시도해주세요.");
    }
  };

  // 취소 버튼 클릭 시 처리 함수
  const handleCancel = () => {
    navigate('/payment');  // '/payment' 페이지로 리다이렉트
  };

  // 현재 날짜와 1달 뒤 날짜 계산
  const today = new Date();
  const nextMonth = new Date();
  nextMonth.setMonth(today.getMonth() + 1);

  // 날짜 형식 변환 함수 (YYYY-MM-DD 형식)
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year} - ${month} - ${day}`;
  };


  return (
    <div className="payment-page">
      {/* 등록 중 메시지 */}
      {isProcessing && (
      <div className="overlay">
        <div className="message">등록 중...</div>
      </div>
      )}
      <h2>구독 결제</h2>
      <div className="payment-container">
        {/* 플랜 요약 섹션 */}
        <div className="plan-summary-box">
          <div className="plan-summary">
            <div className="plan-header">
              <span className="plan-title professional-plan">{subscriptionInfo.planTitle || '선택된 플랜'}</span>
            </div>
            <div className="plan-details">
              <div className="left-section">
                <p className="price">{subscriptionInfo.price ? `${subscriptionInfo.price.toLocaleString()}원 / 월` : '가격 정보 없음'}</p>
                <div className="token-slider">
                  <div className="token-amount">{subscriptionInfo.tokens ? `${subscriptionInfo.tokens.toLocaleString()} tokens` : '토큰 정보 없음'}</div>
                  <input type="range" min="0" max="1000000" value={tokens || 0} readOnly />
                  <div className="slider-range">
                    <span>0 tokens</span>
                    <span>1,000,000 tokens</span>
                  </div>
                </div>
              </div>
              {/* features 리스트 출력 */}
              <ul className="features">
                {subscriptionInfo.features && subscriptionInfo.features.length > 0 ? (
                  subscriptionInfo.features.map((feature, index) => (
                    <li key={index}>
                      <CheckCircle size={16} />
                      {feature}
                    </li>
                  ))
                ) : (
                  <li>플랜 설명이 없습니다.</li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {/* 결제 정보 및 수단 섹션 */}
        <div className="payment-info-box">
          <div className="payment-details">
            <h3>결제 정보</h3>
            <div className="payment-details-list">
              <div className="payment-detail-item">
                <span className="detail-label">결제 금액:</span>
                <span className="detail-value">{subscriptionInfo.price ? `${subscriptionInfo.price.toLocaleString()}원` : '정보 없음'}</span>
              </div>
              <div className="payment-detail-item">
                <span className="detail-label">결제일:</span>
                <span className="detail-value">{formatDate(today)}</span>
              </div>
              <div className="payment-detail-item">
                <span className="detail-label">다음 결제일:</span>
                <span className="detail-value">{formatDate(nextMonth)}</span>
              </div>
            </div>
          </div>
          {/* 빌링 정보 섹션 */}
          {billingInfo && (
            <div className="billing-info-box">
              <h3>등록된 카드</h3>
              <div className="billing-options">
                {billingInfo.map((info, index) => (
                  <div key={index} className="billing-option">
                    <label>
                      <input
                        type="radio"
                        name="billingOption"
                        value={info.customerKey} // Use customerKey as the value
                        onChange={() => setSelectedBilling(info)} // Store the selected billing info
                        checked={selectedBilling?.customerKey === info.customerKey} // Check if this option is selected
                      />
                      <div className="billing-card">
                        {/* 카드 이미지 */}
                        <img
                          src={credit_card} // Correctly set the src to the imported image
                          alt={`Card ${index + 1}`}
                          className="card-image"
                        />
                        {/* 카드 번호 마스킹 처리 */}
                        <div className="masked-card-number">
                          {info.cardNumber} {/* 마스킹된 카드 번호 */}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="payment-methods">
            <h3>카드 등록</h3>
            <div className="methods-buttons">
              <button onClick={requestBillingAuth}>추가하기</button>
            </div>
          </div>

          <div className="terms">
            <label>
              <input type="checkbox" 
              required
              checked={termsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}/>
              [필수] 결제 서비스 이용 약관, 개인정보 처리 동의
            </label>
            <label>
              <input type="checkbox" 
              onChange={(e) => console.log(e.target.checked)} // 선택적인 동의 항목
             />
              [선택] 홍보성 정보 수신 동의
            </label>
          </div>
        </div>
      </div>

      {/* 하단 버튼 섹션 */}
      <div className="payment-action-buttons">
        <button onClick={handleCancel} className="cancel-btn">취소</button>
        <button onClick={handlePayment} className="confirm-btn">결제하기</button>
      </div>
    </div>
  );
};

export default PaymentPage;