import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './SubscribePlanPage.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 가격에 맞는 토큰 계산 함수
const calculateTokens = (price, tokenRate) => Math.round(price * tokenRate);

// 기능별 사용 가능 횟수 계산
const calculateResumeCount = (tokens) => Math.floor(tokens / 3000); 
const calculateEvaluationCount = (tokens) => Math.floor(tokens / 2500); 
const calculateInterviewCount = (tokens) => Math.floor(tokens / 3000);  

const PlanCard = ({ 
  title, basePrice, tokenRate, minPrice, maxPrice, 
  features, isActive, onSubscribe, isFree, price, setPrice, isProfessional 
}) => {
  const tokens = calculateTokens(price, tokenRate); 

  const handlePriceChange = (e) => {
    const newPrice = Math.round(parseInt(e.target.value) / 5000) * 5000; 
    setPrice(newPrice);
  };

  return (
    <div className={`plan-card ${isActive ? 'active' : ''}`}>
      <div className="plan-header">
        <span className={`plan-title ${title.toLowerCase().replace(' ', '-')}`}>{title}</span>
      </div>

      {/* Free & Starter Plan - 가격 정보 표시 */}
      {!isProfessional && <p className="price">{price.toLocaleString()}원 / 월</p>}

      {/* Free & Starter Plan - 토큰 슬라이더 UI 유지 */}
      {!isProfessional && (
        <div className="token-slider">
          <div className="token-amount">{tokens.toLocaleString()} tokens</div>
          <input 
            type="range" 
            min={minPrice} 
            max={maxPrice} 
            value={price} 
            onChange={handlePriceChange}
            disabled={isFree} 
          />
          <div className="slider-range">
            <span>{calculateTokens(minPrice, tokenRate).toLocaleString()} tokens</span>
            <span>{calculateTokens(maxPrice, tokenRate).toLocaleString()} tokens</span>
          </div>
        </div>
      )}

      {/* 플랜 설명 */}
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={16} />
            {feature}
          </li>
        ))}

        {/* Starter Plan 추가 기능 */}
        {title === "Starter plan" && (
          <>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              자기소개서 <span className="highlight-font">{calculateResumeCount(tokens)} 회</span> 생성 가능
            </li>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              자기소개서 평가 <span className="highlight-font">{calculateEvaluationCount(tokens)} 회</span> 가능 
            </li>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              면접 기능 <span className="highlight-font">{calculateInterviewCount(tokens)} 회</span> 이용 가능 
            </li>
          </>
        )}

        {/* Professional Plan 전용 정보 추가 */}
        {isProfessional && (
          <>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              기관 및 연구 단체와의 협력 연계 제공
            </li>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              맞춤형 데이터 분석 및 연구 지원
            </li>
            <li className="highlight-feature">
              <CheckCircle size={16} />
              별도 협의에 따른 서비스 확장 가능
            </li>
          </>
        )}
      </ul>

      {/* 사용 가능 횟수 설명 추가 */}
      {title === "Starter plan" && (
        <p className="usage-info">
          이용가능 횟수는 단일 이용 시 측정된 정보입니다.
        </p>
      )}

      {/* Professional Plan - 별도 안내 문구 추가 (구독 버튼 없음) */}
      {isProfessional && (
        <p className="professional-info">
          ※ 해당 플랜은 기관 연계를 위한 정보 제공 목적입니다.
        </p>
      )}

      {/* 구독 버튼 - Professional 플랜 제외 */}
      {!isProfessional && (
        <button 
          className={`subscribe-btn ${isActive ? 'active' : ''}`} 
          onClick={() => onSubscribe(title, price, tokens)}
        >
          {isActive ? '이용중' : '구독하기'}
        </button>
      )}
    </div>
  );
};

const SubscribePlanPage = () => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState('Free'); 
  const token = getToken();
  const [prices, setPrices] = useState({}); 

  const getSubscription = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubscription(data.subscribePlan);
      } else {
        console.error('구독 정보 불러오기 실패');
      }
    } catch (error) {
      console.error('Error fetching subscription:', error);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const plans = [
    {
      title: 'Free plan',
      basePrice: 0,
      tokenRate: 1,
      minPrice: 0, 
      maxPrice: 0,
      features: ['프리 티어', '신규회원 1회 무료 5000토큰 증정'],
      isActive: subscription === 'Free',
      isFree: true,
    },
    {
      title: 'Starter plan',
      basePrice: 5000,
      tokenRate: 5,
      minPrice: 5000,
      maxPrice: 30000,
      features: ['스타터 툴', '커스텀 지원'],
      isActive: subscription === 'Starter',
      isFree: false,
    },
    {
      title: 'Professional plan',
      basePrice: 50000,
      tokenRate: 6,
      minPrice: 50000,
      maxPrice: 100000,
      features: ['프로 툴', '협력 연계'],
      isActive: subscription === 'professional',
      isFree: false,
      isProfessional: true, 
    },
  ];

  const handleSubscribe = async (planTitle, price, tokens, features) => {
    const token = getToken();
    if (!token) {
      alert('로그인 후 이용해주세요.');
      navigate('/login');
      return;
    }

    try {
      console.log(`구독 요청: ${planTitle}, 가격: ${price}, 토큰: ${tokens}`);
      navigate('/payment/details', { state: { planTitle, price, tokens, features } });
    } catch (error) {
      console.error('Error checking billing info:', error);
    }
  };

  return (
    <div className="subscribe-page">
      <h2>구독정보</h2>
      <div className="subscribe-container">
        <div className="plan-cards">
          {plans.map((plan, index) => (
            <PlanCard 
              key={index} 
              {...plan} 
              price={prices[plan.title] ?? plan.minPrice} 
              setPrice={(newPrice) => setPrices((prev) => ({ ...prev, [plan.title]: newPrice }))} 
              onSubscribe={(title, price, tokens) => handleSubscribe(title, price, tokens, plan.features)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscribePlanPage;