import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInfo from './components/BasicInfo';
import Education from './components/Education';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Certificates from './components/Certificates';
import './styles/ResumeBuilder.css';
import axios from 'axios';
import { getToken } from '../../../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const validateResumeData = (data) => {
  if (!data.title) {
    throw new Error('이력서 제목을 입력해주세요.');
  }
  
  if (!data.template || !data.status) {
    throw new Error('템플릿과 상태 정보가 필요합니다.');
  }

  // 기본 정보 검증
  if (!data.basicInfo.name || !data.basicInfo.email || !data.basicInfo.phone || !data.basicInfo.birthDate) {
    throw new Error('기본 정보의 필수 항목을 모두 입력해주세요.');
  }

  // 학력 검증
  if (data.education.length > 0) {
    data.education.forEach((edu, index) => {
      if (!edu.school || !edu.major || !edu.startDate) {
        throw new Error(`학력사항 ${index + 1}번의 필수 항목을 입력해주세요.`);
      }
    });
  }

  // 경력 검증
  if (data.experience && data.experience.length > 0) {
    // 경력사항이 있는 경우에만 필수 항목 검증
    data.experience.forEach((exp, index) => {
      if (exp.companyName || exp.position || exp.startDate || exp.description) {
        if (!exp.companyName || !exp.position || !exp.startDate) {
          throw new Error(`경력사항 ${index + 1}번의 필수 항목을 입력해주세요.`);
        }
      }
    });
  }

  // 자격증 검증
  if (data.certificates.length > 0) {
    data.certificates.forEach((cert, index) => {
      if (!cert.name || !cert.issuer || !cert.acquisitionDate) {
        throw new Error(`자격증 ${index + 1}번의 필수 항목을 입력해주세요.`);
      }
    });
  }
};

const ResumeBuilderPage = () => {
  const navigate = useNavigate();
  const { resumeId } = useParams();
  const [activeSection, setActiveSection] = useState('basic');
  const [resumeData, setResumeData] = useState({
    title: '',
    template: 'modern',
    status: 'draft',
    basicInfo: {
      name: '',
      email: '',
      phone: '',
      address: '',
      photo: '',
      birthDate: ''
    },
    education: [],
    experience: [],
    skills: [],
    certificates: []
  });

  useEffect(() => {
    const fetchResumeData = async () => {
      if (resumeId) {
        try {
          const token = getToken();
          const response = await axios.get(`${BACKEND_URL}/resume/${resumeId}`, {
            headers: { 'x-auth-token': token }
          });
          if (response.data) {
            setResumeData({
              ...response.data.resumeData,
              title: response.data.title || '제목 없는 이력서',
              template: response.data.template || 'modern',
              status: response.data.status || 'draft'
            });
          }
        } catch (error) {
          console.error('이력서 데이터 불러오기 실패:', error);
          alert('이력서 데이터를 불러오는데 실패했습니다.');
        }
      } else {
        const savedData = sessionStorage.getItem('resumeBuilderData');
        if (savedData) {
          setResumeData(JSON.parse(savedData));
        }
      }
    };

    fetchResumeData();
  }, [resumeId]);

  const handleSave = async () => {
    try {
      // 데이터 유효성 검사 전에 template과 status 확인
      const previewData = {
        ...resumeData,
        _id: resumeId,
        template: resumeData.template || 'modern',
        status: 'draft'
      };

      // 데이터 유효성 검사
      validateResumeData(previewData);
      
      // 세션 스토리지에 데이터 저장
      sessionStorage.setItem('previewResumeData', JSON.stringify(previewData));
      
      // 미리보기 페이지로 이동
      navigate('/tools/resume-builder/preview');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleBack = () => {
    sessionStorage.setItem('resumeBuilderData', JSON.stringify(resumeData));
    navigate(-1);
  };

  const handleDraftSave = async () => {
    try {
      const token = getToken();
      if (!token) {
        alert('로그인이 필요합니다.');
        navigate('/login', { state: { from: window.location.pathname } });
        return;
      }

      // 기본 데이터 검증
      if (!resumeData.basicInfo) {
        throw new Error('기본 정보가 없습니다.');
      }

      const endpoint = resumeId 
        ? `${BACKEND_URL}/resume/save/${resumeId}`
        : `${BACKEND_URL}/resume/save`;
      
      const method = resumeId ? 'put' : 'post';

      // 이미지 압축 처리
      let compressedPhoto = '';
      if (resumeData.basicInfo.photo) {
        compressedPhoto = await compressImage(resumeData.basicInfo.photo);
      }

      const formattedResumeData = {
        ...resumeData,
        experience: resumeData.experience.map(exp => ({
          ...exp,
          description: exp.description || ''
        })),
        basicInfo: {
          ...resumeData.basicInfo,
          photo: compressedPhoto || resumeData.basicInfo.photo
        },
        skills: resumeData.skills.map(skill => ({
          category: skill.category || '',
          items: Array.isArray(skill.items) ? skill.items : []
        }))
      };

      const saveData = {
        resumeData: formattedResumeData,
        template: resumeData.template || 'modern',
        status: 'draft',
        title: resumeData.title || '제목 없는 이력서'
      };

      const response = await axios({
        method,
        url: endpoint,
        data: saveData,
        headers: {
          'x-auth-token': token,
          'Content-Type': 'application/json'
        }
      });

      if (!response.data || !response.data.resumeId) {
        throw new Error('서버 응답이 올바르지 않습니다.');
      }

      sessionStorage.setItem('resumeBuilderData', JSON.stringify(formattedResumeData));
      alert('임시저장되었습니다.');
      
      // 저장 후 목록 페이지로 이동
      navigate('/mypage/saved-builder-resumes');
      
    } catch (error) {
      console.error('Error saving draft:', error);
      if (error.response?.data) {
        alert(error.response.data.message || '서버 오류가 발생했습니다.');
      } else {
        alert(error.message || '임시저장 중 오류가 발생했습니다.');
      }
    }
  };

  // 이미지 압축 함수 추가
  const compressImage = (base64Image) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Image;
      
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;
        
        let width = img.width;
        let height = img.height;
        
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        
        canvas.width = width;
        canvas.height = height;
        
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        
        resolve(canvas.toDataURL('image/jpeg', 0.7));
      };
    });
  };

  const renderContent = () => {
    const sections = ['basic', 'education', 'experience', 'skills', 'certificates'];
    const currentIndex = sections.indexOf(activeSection);
    
    const content = (() => {
      switch (activeSection) {
        case 'basic':
          return (
            <BasicInfo 
              data={resumeData.basicInfo} 
              onChange={(data) => setResumeData({...resumeData, basicInfo: data})} 
            />
          );
        case 'education':
          return (
            <Education 
              educations={resumeData.education}
              onChange={(data) => setResumeData({...resumeData, education: data})}
            />
          );
        case 'experience':
          return (
            <Experience 
              data={resumeData.experience}
              onChange={(data) => setResumeData({...resumeData, experience: data})}
            />
          );
        case 'skills':
          return (
            <Skills 
              data={resumeData.skills}
              onChange={(data) => setResumeData({...resumeData, skills: data})}
            />
          );
        case 'certificates':
          return (
            <Certificates 
              data={resumeData.certificates}
              onChange={(data) => setResumeData({...resumeData, certificates: data})}
            />
          );
        default:
          return null;
      }
    })();

    return (
      <>
        <div className="section-content-body">
          {content}
        </div>
        <div className="section-navigation">
          <button 
            className="navigation-button"
            onClick={() => {
              if (currentIndex > 0) {
                setActiveSection(sections[currentIndex - 1]);
              }
            }}
            disabled={currentIndex === 0}
          >
            ← 이전 단계
          </button>
          <button 
            className="navigation-button"
            onClick={() => {
              if (currentIndex < sections.length - 1) {
                setActiveSection(sections[currentIndex + 1]);
              }
            }}
            disabled={currentIndex === sections.length - 1}
          >
            다음 단계 →
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="resume-builder">
      <h1>이력서 작성</h1>
      <div className="resume-title-section">
        <div className="title-header">
          <div className="title-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2E74FF">
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <polyline points="14 2 14 8 20 8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          <input
            type="text"
            placeholder="이력서 제목을 입력해주세요"
            value={resumeData.title}
            onChange={(e) => setResumeData({...resumeData, title: e.target.value})}
            className="title-input"
          />
        </div>
      </div>
      <div className="resume-sections">
        <div className="left-section">
          <nav className="section-nav" style={{ counterReset: 'section 0' }}>
            <button 
              className={activeSection === 'basic' ? 'active' : ''} 
              onClick={() => setActiveSection('basic')}
            >
              기본 정보
            </button>
            <button 
              className={activeSection === 'education' ? 'active' : ''} 
              onClick={() => setActiveSection('education')}
            >
              학력사항
            </button>
            <button 
              className={activeSection === 'experience' ? 'active' : ''} 
              onClick={() => setActiveSection('experience')}
            >
              경력사항
            </button>
            <button 
              className={activeSection === 'skills' ? 'active' : ''} 
              onClick={() => setActiveSection('skills')}
            >
              보유기술
            </button>
            <button 
              className={activeSection === 'certificates' ? 'active' : ''} 
              onClick={() => setActiveSection('certificates')}
            >
              자격증
            </button>
          </nav>
          <div className="resume-info-box">
            <h3>이력서 생성기 안내</h3>
            <p>작성하신 이력서는 다음 서비스에 활용됩니다:</p>
            <div className="service-list">
              <div className="service-item">
                🤖 AI 면접 준비
                <span>이력서 기반 맞춤형 면접 질문</span>
              </div>
              <div className="service-item">
                📝 자기소개서 생성
                <span>경력과 스킬 기반 자기소개서 작성 지원</span>
              </div>
              <div className="service-item">
                📊 잡매칭
                <span>이력서바탕으로 맞춤형 채용공고 매칭</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="section-content">
          {renderContent()}
        </div>
      </div>

      <div className="action-buttons">

        <div className="right-buttons">
          <button onClick={handleDraftSave} className="draft-button">임시저장</button>
          <button onClick={handleSave} className="preview-button">미리보기</button>
          <button onClick={handleSave} className="save-button">저장</button>
        </div>
      </div>
    </div>
  );
};

export default ResumeBuilderPage;