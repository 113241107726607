import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { motion } from 'framer-motion';
import SpinningLogo from '../SpinningLogo';

const InterviewResult = () => {
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const validateResult = (result) => {
    if (!result) return false;
    
    // 필수 점수 필드 검증
    const scores = ['overallScore', 'technicalScore', 'communicationScore'];
    const hasValidScores = scores.every(score => 
      typeof result[score] === 'number' && result[score] >= 0
    );
    
    // 필수 배열 필드 검증 - 배열은 존재하기만 하면 됨
    const arrays = ['strengths', 'weaknesses', 'improvements'];
    const hasValidArrays = arrays.every(arr => 
      Array.isArray(result[arr])
    );
    
    // details 배열 검증 추가
    const hasValidDetails = Array.isArray(result.details);
    
    return hasValidScores && hasValidArrays && hasValidDetails;
  };

  useEffect(() => {
    const fetchResult = async () => {
      try {
        setLoading(true);
        const token = getToken();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/interview/${interviewId}/evaluation`,
          {
            headers: {
              'x-auth-token': token
            }
          }
        );

        console.log('받은 결과 데이터:', response.data);
        
        if (response.data.success && validateResult(response.data.data)) {
          setResult(response.data.data);
        } else {
          throw new Error('불완전한 결과 데이터');
        }
      } catch (error) {
        console.error('결과 조회 오류:', error);
        setError('결과를 불러오는데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchResult();
  }, [interviewId]);

  if (loading) {
    return (
      <LoadingContainer>
        <SpinningLogo text="면접 결과를 분석하고 있습니다..." />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <ErrorContainer>
        <ErrorText>{error}</ErrorText>
        <Button onClick={() => navigate('/tools/ai-interviewer')}>
          면접 목록으로 돌아가기
        </Button>
      </ErrorContainer>
    );
  }

  if (!result) {
    return (
      <ErrorContainer>
        <ErrorText>결과를 찾을 수 없습니다.</ErrorText>
        <Button onClick={() => navigate('/tools/ai-interviewer')}>
          면접 목록으로 돌아가기
        </Button>
      </ErrorContainer>
    );
  }

  return (
    <PageContainer>
      {loading ? (
        <LoadingWrapper>
          <SpinningLogo />
          <LoadingText>면접 결과를 분석하고 있습니다...</LoadingText>
        </LoadingWrapper>
      ) : error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : result && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <ResultContainer>
            <Header>
              <Title>면접 결과 분석</Title>
              <SubTitle>AI가 분석한 당신의 면접 결과입니다</SubTitle>
            </Header>

            <ScoreOverview>
              <ScoreCard>
                <CircularProgressbar
                  value={result.overallScore}
                  text={`${result.overallScore}점`}
                  styles={buildStyles({
                    pathColor: `#2E74FF`,
                    textColor: '#2E74FF',
                    trailColor: '#F0F4FF'
                  })}
                />
                <ScoreLabel>종합 평가</ScoreLabel>
              </ScoreCard>

              <ScoreCard>
                <CircularProgressbar
                  value={result.technicalScore}
                  text={`${result.technicalScore}점`}
                  styles={buildStyles({
                    pathColor: '#36B37E',
                    textColor: '#36B37E',
                    trailColor: '#F0F4FF'
                  })}
                />
                <ScoreLabel>기술력</ScoreLabel>
              </ScoreCard>

              <ScoreCard>
                <CircularProgressbar
                  value={result.communicationScore}
                  text={`${result.communicationScore}점`}
                  styles={buildStyles({
                    pathColor: '#FF5630',
                    textColor: '#FF5630',
                    trailColor: '#F0F4FF'
                  })}
                />
                <ScoreLabel>의사소통</ScoreLabel>
              </ScoreCard>
            </ScoreOverview>

            <EvaluationGrid>
              <EvalSection>
                <EvalTitle>
                  <IconWrapper>💪</IconWrapper>
                  강점
                </EvalTitle>
                <CardList>
                  {result.strengths.map((strength, idx) => (
                    <StrengthCard key={idx}>
                      <CardContent>{strength}</CardContent>
                    </StrengthCard>
                  ))}
                </CardList>
              </EvalSection>

              <EvalSection>
                <EvalTitle>
                  <IconWrapper>💡</IconWrapper>
                  개선사항
                </EvalTitle>
                <CardList>
                  {result.improvements.map((improvement, idx) => (
                    <ImprovementCard key={idx}>
                      <CardContent>{improvement}</CardContent>
                    </ImprovementCard>
                  ))}
                </CardList>
              </EvalSection>
            </EvaluationGrid>

            <DetailedAnalysis>
              <SectionTitle>
                <IconWrapper>📝</IconWrapper>
                상세 분석
              </SectionTitle>
              {result.details?.map((detail, idx) => (
                <AnswerCard key={idx}>
                  <QuestionText>{detail.question}</QuestionText>
                  <AnswerText>{detail.answer}</AnswerText>
                  <FeedbackBox>
                    <FeedbackIcon>💭</FeedbackIcon>
                    {detail.feedback}
                  </FeedbackBox>
                </AnswerCard>
              ))}
            </DetailedAnalysis>
          </ResultContainer>
        </motion.div>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  background: #FAFBFF;
  padding: 3rem 2rem;
  
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
  }
`;

const ResultContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  border-radius: 32px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.05);
  padding: 3.5rem;
  
  @media (max-width: 768px) {
    padding: 2rem;
    border-radius: 24px;
  }
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 800;
  color: #1A1F36;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.p`
  color: #4E5D78;
  font-size: 1.1rem;
  font-weight: 500;
`;

const ScoreOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 3rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const ScoreCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 24px;
  text-align: center;
  border: 1px solid #E5E9F2;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  }
`;

const ScoreLabel = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #4E5D78;
  margin-top: 1rem;
`;

const EvaluationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  margin: 4rem 0;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const EvalSection = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 24px;
  border: 1px solid #E5E9F2;
`;

const EvalTitle = styled.h3`
  font-size: 1.4rem;
  color: #1A1F36;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #E5E9F2;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FeedbackCard = styled.div`
  padding: 1.25rem;
  background: #F7F9FC;
  border-radius: 16px;
  transition: all 0.2s ease;
  
  &:hover {
    background: #EFF3F9;
  }
`;

const DetailedAnalysis = styled.div`
  margin-top: 4rem;
`;

const AnswerCard = styled.div`
  background: white;
  padding: 2.5rem;
  border-radius: 24px;
  border: 1px solid #E5E9F2;
  margin-bottom: 2rem;
  
  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  }
`;

const QuestionText = styled.h4`
  color: #1A1F36;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  
  &::before {
    content: 'Q';
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    background: #2E74FF;
    color: white;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;
  }
`;

const AnswerText = styled.p`
  color: #4E5D78;
  line-height: 1.8;
  font-size: 1.1rem;
  background: #F7F9FC;
  padding: 1.5rem 1.5rem 1.5rem 4rem;
  border-radius: 16px;
  margin-bottom: 1.5rem;
  position: relative;
  
  &::before {
    content: 'A';
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    background: #36B37E;
    color: white;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;
  }
`;

const FeedbackBox = styled.div`
  background: #F0F7FF;
  padding: 1.5rem;
  border-radius: 16px;
  color: #2E5AAC;
  font-size: 1.05rem;
  line-height: 1.7;
  position: relative;
  
  &::before {
    content: '💡 AI 피드백';
    position: absolute;
    top: -12px;
    left: 16px;
    background: #2E5AAC;
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
  }
`;

const FeedbackIcon = styled.span`
  margin-right: 0.5rem;
  font-size: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 1rem 2rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:first-child {
    background-color: #f8fafc;
    color: #2E74FF;
    border: 1px solid #e4ebff;
    
    &:hover {
      background-color: #e4ebff;
    }
  }
  
  &:last-child {
    background-color: #2E74FF;
    color: white;
    
    &:hover {
      background-color: #1b5fd9;
    }
  }
`;

const ErrorContainer = styled.div`  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin: 2rem;
  background-color: #fff3f3;
  border-radius: 8px;
  color: #e74c3c;
  font-size: 1.1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ErrorText = styled.p`
  color: #e74c3c;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;

const LoadingText = styled.p`
  color: #2E74FF;
  font-size: 1.2rem;
  margin-top: 1rem;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const IconWrapper = styled.span`
  margin-right: 0.5rem;
  font-size: 1.5rem;
`;

const StrengthCard = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 10px;
  border-left: 4px solid #36B37E;
  box-shadow: 0 2px 4px rgba(54, 179, 126, 0.1);
`;

const ImprovementCard = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 10px;
  border-left: 4px solid #FF5630;
  box-shadow: 0 2px 4px rgba(255, 86, 48, 0.1);
`;

const CardContent = styled.p`
  margin: 0;
  line-height: 1.5;
`;

const SectionTitle = styled.h3`
  color: #2E74FF;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

export default InterviewResult;
