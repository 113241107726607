import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './UserDetailModal.css';
import robotIcon from '../assets/images/landingpage/roboticon.svg';
import { FaCheck, FaCalendarAlt, FaClock, FaLaptopHouse, FaFile } from 'react-icons/fa'; // React Icons 라이브러리 사용
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { getToken } from '../utils/auth'; // auth 유틸리티에서 토큰을 가져오는 함수를 import
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initialUserDetailState, validateUserDetail } from '../utils/userDetailUtils';
import { useNavigate } from 'react-router-dom';

// axios 인스턴스 생성
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001',
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const UserDetailModal = ({ onClose, initialData = null }) => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(initialData || initialUserDetailState);
  const [step, setStep] = useState(initialData ? 1 : 0); // 0으로 초기화하여 새로운 첫 화면을 표시
  const [selectedField, setSelectedField] = useState('');
  const [selectedExperience, setSelectedExperience] = useState('');
  const [selectedEmploymentType, setSelectedEmploymentType] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [careerStatus, setCareerStatus] = useState('');
  const [education, setEducation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [recentPosition, setRecentPosition] = useState('');
  const [recentCompany, setRecentCompany] = useState('');
  const [careerHighlights, setCareerHighlights] = useState(['', '', '']);
  const [skills, setSkills] = useState(['', '', '']);
  const [certifications, setCertifications] = useState(['', '']);
  const [salaryRange, setSalaryRange] = useState([20000000, 45000000]);
  const [startDate, setStartDate] = useState('');
  const [workHours, setWorkHours] = useState('09:00-18:00');
  const [remoteWork, setRemoteWork] = useState('유연 근무 불가능');
  const [portfolioLinks, setPortfolioLinks] = useState([]);
  const [portfolioLink, setPortfolioLink] = useState('');
  const [error, setError] = useState('');
  const modalRef = useRef();
  const [isEducationValid, setIsEducationValid] = useState(false);
  const [isExperienceValid, setIsExperienceValid] = useState(false);
  const [isPositionValid, setIsPositionValid] = useState(false);
  const [isCompanyValid, setIsCompanyValid] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [showEmailDetails, setShowEmailDetails] = useState(false);
  const [showSmsDetails, setShowSmsDetails] = useState(false);
  const [isNewGraduate, setIsNewGraduate] = useState(false);
  const [graduationYear, setGraduationYear] = useState('');
  const [gpa, setGpa] = useState('');
  const [majorExperience, setMajorExperience] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    setIsEducationValid(!!education);
    setIsExperienceValid(!!yearsOfExperience);
    setIsPositionValid(!!recentPosition);
    setIsCompanyValid(!!recentCompany);
  }, [education, yearsOfExperience, recentPosition, recentCompany]);

  useEffect(() => {
    if (initialData) {
      // 초기 데이터로 상태 설정
      setSelectedField(initialData.major || '');
      setSelectedExperience(initialData.experience || '');
      setSelectedEmploymentType(initialData.employmentType || '');
      setSelectedWorkType(initialData.workType || '');
      setSelectedRegion(initialData.preferredRegion || '');
      setCareerStatus(initialData.careerStatus || '');
      setEducation(initialData.education || '');
      setYearsOfExperience(initialData.yearsOfExperience || '');
      setRecentPosition(initialData.recentPosition || '');
      setRecentCompany(initialData.recentCompany || '');
      setSkills(initialData.skills || []);
      setCertifications(initialData.certifications || []);
      setSalaryRange(initialData.salaryRange || [20000000, 45000000]);
      setStartDate(initialData.startDate || '');
      setWorkHours(initialData.workHours || '09:00-18:00');
      setRemoteWork(initialData.remoteWork || '유연 근무 불가능');
      setPortfolioLinks(initialData.portfolioLinks || []);
      setIsNewGraduate(initialData.isNewGraduate || false);
      setGraduationYear(initialData.graduationYear || '');
      setGpa(initialData.gpa || '');
      setMajorExperience(initialData.majorExperience || '');
    }
  }, [initialData]);

  const handleFieldSelect = (field) => {
    setSelectedField(field);
    setUserDetail(prev => ({ ...prev, major: field }));
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
    setUserDetail(prev => ({ ...prev, experience }));
  };

  const handleEmploymentTypeSelect = (type) => {
    setSelectedEmploymentType(type);
    setUserDetail(prev => ({ ...prev, employmentType: type }));
  };

  const handleWorkTypeSelect = (type) => {
    setSelectedWorkType(type);
  };

  const handleRegionSelect = (region) => {
    setSelectedRegion(region);
    setUserDetail(prev => ({ ...prev, preferredRegion: region }));
  };

  const handleCareerSelect = (status) => {
    setCareerStatus(status);
    setSelectedExperience(status);
    setUserDetail(prev => ({ ...prev, careerStatus: status, experience: status }));
    setError('');
  };

  const handleNextStep = () => {
    switch (step) {
      case 1:
        if (!selectedField) {
          setError('분야를 선택해주세요.');
          return;
        }
        break;
      case 2:
        if (!selectedEmploymentType || !selectedWorkType) {
          setError('고용 형태와 근무 방식을 모두 선택해주세요.');
          return;
        }
        break;
      case 3:
        if (!selectedRegion) {
          setError('선호하는 지역을 선택해주세요.');
          return;
        }
        break;
      case 5:
        if (!education) {
          setError('최종 학력을 선택해주세요.');
          return;
        }
        if (careerStatus === '경력' && (!yearsOfExperience || !recentPosition || !recentCompany)) {
          setError('모든 경력 정보를 입력해주세요.');
          return;
        }
        break;
      // ... 다른 스텝들에 대한 유효성 검사 추가 ...
    }
    setStep(step + 1);
    setError('');
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const allUserData = {
        major: selectedField || '',
        experience: selectedExperience || '신입',
        employmentType: selectedEmploymentType || '풀임',
        preferredRegion: selectedRegion || '서울',
        careerStatus: careerStatus || '신입',
        education: education || '학사',
        yearsOfExperience: careerStatus === '경력' ? yearsOfExperience : '',
        recentPosition: careerStatus === '경력' ? recentPosition : '',
        recentCompany: careerStatus === '경력' ? recentCompany : '',
        minSalary: salaryRange[0] || 20000000,
        maxSalary: salaryRange[1] || 45000000,
        startDate: startDate || new Date().toISOString().split('T')[0],
        workHours: workHours || '09:00-18:00',
        remoteWork: remoteWork || '유연 근무 불가능',
        portfolioLinks: portfolioLinks?.filter(link => link.trim() !== '') || [],
        careerHighlights: careerHighlights?.filter(highlight => highlight.trim() !== '') || [],
        skills: skills?.filter(skill => skill.trim() !== '') || [],
        certifications: certifications?.filter(cert => cert.trim() !== '') || [],
        isNewGraduate: isNewGraduate || false,
        graduationYear: isNewGraduate ? graduationYear : '',
        gpa: isNewGraduate ? gpa : '',
        majorExperience: majorExperience || ''
      };

      const response = await api.post('/user/detail', allUserData);
      
      if (response.status === 200) {
        toast.success('프로필이 성공적으로 저장되었습니다!');
        onClose();
        navigate('/tools/resume-generator/setinfo');
      } else {
        throw new Error('서버 응답이 실패했습니다.');
      }
    } catch (error) {
      console.error('Error saving user details:', error);
      toast.error(error.message || '프로필 저장 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  const addCareerHighlight = () => {
    if (careerHighlights.length < 3) {
      setCareerHighlights([...careerHighlights, '']);
    }
  };

  const handleCareerHighlightChange = (index, value) => {
    const newHighlights = [...careerHighlights];
    newHighlights[index] = value;
    setCareerHighlights(newHighlights);
  };

  const removeCareerHighlight = (index) => {
    const newHighlights = careerHighlights.filter((_, i) => i !== index);
    setCareerHighlights(newHighlights);
  };

  const formatSalary = (value) => (value / 10000).toFixed(0) + '만원';

  const handleSalaryChange = (newValue) => {
    setSalaryRange(newValue);
  };

  const handleEducationChange = (e) => {
    setEducation(e.target.value);
    setUserDetail(prev => ({ ...prev, education: e.target.value }));
  };

  const renderStepContent = () => {
    switch (step) {
      case 0: // 새로운 첫 화면
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <h2 className="user-detail-modal__title">사용자님의 분야에 대해 단한 질문을 드릴!</h2>
            <p className="user-detail-modal__description">사용자님의 정보에 맞는 취업 정보를 얻으세요!</p>
            <img src={robotIcon} alt="AI 로봇" className="user-detail-modal__intro-image" />
            <p className="user-detail-modal__token-info">설문조사를 완료하시면 <strong>10,000 토큰</strong>을 지급해 드립니다!</p>
            <div className="user-detail-modal__button-group">
              <button className="user-detail-modal__button" onClick={() => setStep(1)}>
                시작해보기
              </button>
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handleSkip}>
                나중에 하기
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">1</div>
            <h2 className="user-detail-modal__title">사용자님의 분야를 택해주세요!</h2>
            <p className="user-detail-modal__description">(1/7 step)</p>
            <div className="user-detail-modal__button-grid">
              {['개발/IT', '디자인', '인사/HR', '전략/마케', '회계/세무', '기획', '설/인테리어', '경제/경영', '보험/설계'].map((field, index) => (
                <button
                  key={index}
                  className={`user-detail-modal__field-button ${selectedField === field ? 'selected' : ''}`}
                  onClick={() => handleFieldSelect(field)}
                >
                  {field}
                </button>
              ))}
            </div>
            {error && <p className="user-detail-modal__error">{error}</p>}
            <button 
              className="user-detail-modal__next-button" 
              onClick={handleNextStep}
              disabled={!selectedField}
            >
              다음
            </button>
          </div>
        );
      case 2:
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">2</div>
            <h2 className="user-detail-modal__title">고용 형태와 근무 방식을 모두 선택해주세요</h2>
            <p className="user-detail-modal__description">(2/7 step)</p>
            <div className="user-detail-modal__form-container">
              <div className="user-detail-modal__form-group">
                <label>고용 형태</label>
                <div className="user-detail-modal__button-row">
                  {['풀임', '파트타', '프리랜서', '인턴'].map((type, index) => (
                    <button
                      key={index}
                      className={`user-detail-modal__employment-type-button ${selectedEmploymentType === type ? 'selected' : ''}`}
                      onClick={() => handleEmploymentTypeSelect(type)}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>
              <div className="user-detail-modal__form-group">
                <label>근무 방식</label>
                <div className="user-detail-modal__button-row">
                  {['원격근있음', '원격근무없음', '혼합근무', '상관없음'].map((type, index) => (
                    <button
                      key={index}
                      className={`user-detail-modal__work-style-button ${selectedWorkType === type ? 'selected' : ''}`}
                      onClick={() => handleWorkTypeSelect(type)}
                    >
                      {type}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {error && <p className="user-detail-modal__error">{error}</p>}
            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button 
                className="user-detail-modal__button" 
                onClick={handleNextStep}
                disabled={!selectedEmploymentType}
              >
                다음
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">3</div>
            <h2 className="user-detail-modal__title">선호하는 지역 선택해주세요</h2>
            <p className="user-detail-modal__description">(3/7 step)</p>
            <div className="user-detail-modal__button-container">
              {['전국', '서울', '기 북부', '경기 남부', '인천', '대전', '세종', '충북', '남', 
                '광주', '전북', '전남', '대구', '경북', '경남', '부산', '울산', '강원', '제주'].map((region, index) => (
                <button
                  key={index}
                  className={`user-detail-modal__region-button ${selectedRegion === region ? 'selected' : ''}`}
                  onClick={() => handleRegionSelect(region)}
                >
                  {region}
                </button>
              ))}
            </div>
            {error && <p className="user-detail-modal__error">{error}</p>}
            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button 
                className="user-detail-modal__button" 
                onClick={handleNextStep}
                disabled={!selectedRegion}
              >
                다음
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">4</div>
            <h2 className="user-detail-modal__title">경력 여부를 선택해세요</h2>
            <p className="user-detail-modal__description">(4/7 step)</p>
            <div className="user-detail-modal__button-grid">
              {['신입', '경력'].map((status, index) => (
                <button
                  key={index}
                  className={`user-detail-modal__field-button ${careerStatus === status ? 'selected' : ''}`}
                  onClick={() => handleCareerSelect(status)}
                >
                  {status}
                </button>
              ))}
            </div>
            {error && <p className="user-detail-modal__error">{error}</p>}
            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button 
                className="user-detail-modal__button" 
                onClick={handleNextStep}
                disabled={!careerStatus}
              >
                다음
              </button>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="user-detail-modal__content" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">5</div>
            <h2 className="user-detail-modal__title">경력 정보를 입력해주세요</h2>
            <p className="user-detail-modal__description">(5/7 step)</p>
            <div className="user-detail-modal__form-group">
              <label>최종 학력</label>
              <select
                value={education}
                onChange={handleEducationChange}
                className="user-detail-modal__select"
              >
                <option value="">선택해주세요</option>
                <option value="고졸">고졸</option>
                <option value="전문대졸">전문대졸</option>
                <option value="학사">학사</option>
                <option value="석사">석사</option>
                <option value="박사">박사</option>
              </select>
            </div>
            {careerStatus === '경력' && (
              <>
                <div className="user-detail-modal__form-group">
                  <label>경력 연차</label>
                  <input
                    type="text"
                    value={yearsOfExperience}
                    onChange={(e) => {
                      setYearsOfExperience(e.target.value);
                      setUserDetail(prev => ({ ...prev, yearsOfExperience: e.target.value }));
                    }}
                    placeholder="예: 3년"
                  />
                </div>
                <div className="user-detail-modal__form-group">
                  <label>최근 직책</label>
                  <input
                    type="text"
                    value={recentPosition}
                    onChange={(e) => {
                      setRecentPosition(e.target.value);
                      setUserDetail(prev => ({ ...prev, recentPosition: e.target.value }));
                    }}
                    placeholder="예: 주니어 개발자"
                  />
                </div>
                <div className="user-detail-modal__form-group">
                  <label>최근 근무 회사</label>
                  <input
                    type="text"
                    value={recentCompany}
                    onChange={(e) => {
                      setRecentCompany(e.target.value);
                      setUserDetail(prev => ({ ...prev, recentCompany: e.target.value }));
                    }}
                    placeholder="예: ABC 기업"
                  />
                </div>
              </>
            )}
            <div className="user-detail-modal__form-group">
              <label>주요 경력 사항 (최대 3개)</label>
              {careerHighlights.map((highlight, index) => (
                <input
                  key={index}
                  type="text"
                  value={highlight}
                  onChange={(e) => {
                    const newHighlights = [...careerHighlights];
                    newHighlights[index] = e.target.value;
                    setCareerHighlights(newHighlights);
                  }}
                  placeholder={`주요 경력 사항 ${index + 1}`}
                />
              ))}
            </div>
            <div className="user-detail-modal__form-group">
              <label>보유 기술 (최대 3개)</label>
              {skills.map((skill, index) => (
                <input
                  key={index}
                  type="text"
                  value={skill}
                  onChange={(e) => {
                    const newSkills = [...skills];
                    newSkills[index] = e.target.value;
                    setSkills(newSkills);
                  }}
                  placeholder={`기술 ${index + 1}`}
                />
              ))}
            </div>
            {error && <p className="user-detail-modal__error">{error}</p>}
            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button 
                className="user-detail-modal__button" 
                onClick={handleNextStep}
                disabled={!education || (careerStatus === '경력' && (!yearsOfExperience || !recentPosition || !recentCompany))}
              >
                다
              </button>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="user-detail-modal__content user-detail-modal__content--compact" ref={modalRef}>
            <div className="user-detail-modal__header">
              <div className="user-detail-modal__step-indicator">6</div>
              <h2 className="user-detail-modal__title">희망 연봉 및 근무 조건</h2>
              <p className="user-detail-modal__description">(6/7 step)</p>
              <p className="user-detail-modal__description">당신의 이상적인 근무 환경 설정해주세요.</p>
            </div>
            
            <div className="user-detail-modal__form-container--step6">
              <div className="user-detail-modal__salary-range--step6">
                <label className="user-detail-modal__label--step6">희망 연봉</label>
                <Slider
                  range
                  min={20000000}
                  max={200000000}
                  step={1000000}
                  value={salaryRange}
                  onChange={handleSalaryChange}
                  className="user-detail-modal__salary-slider--step6"
                />
                <div className="user-detail-modal__salary-values--step6">
                  <span>{formatSalary(salaryRange[0])}</span>
                  <span>~</span>
                  <span>{formatSalary(salaryRange[1])}</span>
                </div>
              </div>
              
              <div className="user-detail-modal__work-conditions--step6">
                <div className="user-detail-modal__work-condition-item">
                  <FaCalendarAlt className="user-detail-modal__work-condition-icon" />
                  <div className="user-detail-modal__work-condition-content">
                    <label className="user-detail-modal__label--step6">근무 시작 가능일</label>
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="user-detail-modal__input--step6"
                    />
                  </div>
                </div>
                <div className="user-detail-modal__work-condition-item">
                  <FaClock className="user-detail-modal__work-condition-icon" />
                  <div className="user-detail-modal__work-condition-content">
                    <label className="user-detail-modal__label--step6">근무 가능 시간대</label>
                    <select
                      value={workHours}
                      onChange={(e) => setWorkHours(e.target.value)}
                      className="user-detail-modal__select--step6"
                    >
                      <option value="09:00-18:00">09:00-18:00 (일반)</option>
                      <option value="08:00-17:00">08:00-17:00 (조기)</option>
                      <option value="10:00-19:00">10:00-19:00 (늦은)</option>
                      <option value="13:00-22:00">13:00-22:00 (오)</option>
                      <option value="22:00-07:00">22:00-07:00 (야간)</option>
                      <option value="flexible">자율근무제</option>
                    </select>
                  </div>
                </div>
                <div className="user-detail-modal__work-condition-item">
                  <FaLaptopHouse className="user-detail-modal__work-condition-icon" />
                  <div className="user-detail-modal__work-condition-content">
                    <label className="user-detail-modal__label--step6">유연 근무제</label>
                    <select
                      value={remoteWork}
                      onChange={(e) => setRemoteWork(e.target.value)}
                      className="user-detail-modal__select--step6"
                    >
                      <option value="유연 근제 가능">유연 무제 불가능</option>
                      <option value="유연 근무제 가능">유연 근무제 가능</option>
                    </select>
                  </div>
                </div>
                {/* 추가 조건이 있다면 여기에 추가 */}
              </div>
            </div>

            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button className="user-detail-modal__button" onClick={handleNextStep}>
                다음
              </button>
            </div>
          </div>
        );
      case 7:
        return (
          <div className="user-detail-modal__content user-detail-modal__content--compact" ref={modalRef}>
            <div className="user-detail-modal__step-indicator">7</div>
            <div className="user-detail-modal__header">
              <h2 className="user-detail-modal__title">포트폴리오 제출</h2>
              <p className="user-detail-modal__description">(7/7 step)</p>
            </div>
            
            <div className="user-detail-modal__form-container--step7">
              <div className="user-detail-modal__form-group--step7">
                <label className="user-detail-modal__label--step7">포트폴리오 링크</label>
                <div className="user-detail-modal__link-input-group">
                  <input
                    type="text"
                    value={portfolioLink}
                    onChange={(e) => setPortfolioLink(e.target.value)}
                    placeholder="https://example.com/portfolio"
                    className="user-detail-modal__input--step7"
                  />
                  <button 
                    className="user-detail-modal__add-link-button"
                    onClick={() => {
                      if (portfolioLink) {
                        setPortfolioLinks([...portfolioLinks, portfolioLink]);
                        setPortfolioLink('');
                      }
                    }}
                    disabled={!portfolioLink}
                  >
                    추가
                  </button>
                </div>
                {portfolioLinks.length > 0 && (
                  <ul className="user-detail-modal__link-list">
                    {portfolioLinks.map((link, index) => (
                      <li key={index} className="user-detail-modal__link-item">
                        <a href={link} target="_blank" rel="noopener noreferrer" className="user-detail-modal__link">
                          {link}
                        </a>
                        <button 
                          className="user-detail-modal__remove-link-button"
                          onClick={() => setPortfolioLinks(portfolioLinks.filter((_, i) => i !== index))}
                        >
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="user-detail-modal__navigation">
              <button className="user-detail-modal__button user-detail-modal__button--secondary" onClick={handlePrevStep}>
                이전
              </button>
              <button 
                className="user-detail-modal__button" 
                onClick={handleNextStep}
                disabled={portfolioLinks.length === 0}
              >
                다음
              </button>
              <button 
                className="user-detail-modal__button user-detail-modal__button--skip"
                onClick={handleNextStep}
              >
                건너뛰기
              </button>
            </div>
          </div>
        );
      case 8: // 완료 화면
        return (
          <div className="user-detail-modal__content user-detail-modal__content--completion" ref={modalRef}>
            <div className="user-detail-modal__completion-container">
              <div className="user-detail-modal__completion-icon">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
              </div>
              <h2 className="user-detail-modal__completion-title">설문조사 완료!</h2>
              <p className="user-detail-modal__completion-message">
                프로필 설정이 완료되었습니다.
              </p>
              <div className="user-detail-modal__token-message">
                <span className="user-detail-modal__token-icon">🎁</span>
                <strong>2,000 토큰</strong>이 지급되었습니다.
              </div>
            </div>

            <div className="user-detail-modal__next-steps">
              <p>이제 AI 서비스를 이용해보세요!</p>
              <button 
                className="user-detail-modal__button user-detail-modal__button--primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? '저장 중...' : '시작하기'}
                {!isLoading && <span className="user-detail-modal__button-arrow">→</span>}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleSkip = () => {
    const now = new Date().getTime();
    localStorage.setItem('lastSkipTime', now.toString());
    onClose();
    toast.info('프로필은 마이페이지에서 나중에 설정할 수 있습니다.');
  };

  return (
    <div className="user-detail-modal">
      {renderStepContent()}
      <ToastContainer />
    </div>
  );
};

UserDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default UserDetailModal;
