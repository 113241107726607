// pages/tools/jobmatching/JobMatchingPage.jsx
// 직무 매칭 페이지
// 마지막 기능, 워크넷 api,연동 후 추가 예정
import React, { useState } from 'react';
import styled from 'styled-components';

const JobMatchingPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    skills: [],
    experience: '',
    education: '',
    preferredLocation: '',
    preferredIndustry: '',
    expectedSalary: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSkillChange = (skill) => {
    setFormData(prev => ({
      ...prev,
      skills: prev.skills.includes(skill)
        ? prev.skills.filter(s => s !== skill)
        : [...prev.skills, skill]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: API 연동 후 매칭 결과 표시
    setStep(2);
  };

  return (
    <Container>
      <Title>맞춤 직무 매칭</Title>
      
      <ComingSoonBanner>
        <span>🚀 COMING SOON</span>
        <p>더 나은 서비스 제공을 위해 준비 중입니다</p>
      </ComingSoonBanner>
      
      {step === 1 && (
        <Form onSubmit={handleSubmit}>
          <Section>
            <SectionTitle>보유 기술</SectionTitle>
            <SkillsGrid>
              {['JavaScript', 'Python', 'Java', 'React', 'Node.js', 'Spring', 
                'AWS', 'Docker', 'Kubernetes', 'SQL', 'NoSQL', 'Git'].map(skill => (
                <SkillButton
                  key={skill}
                  type="button"
                  selected={formData.skills.includes(skill)}
                  onClick={() => handleSkillChange(skill)}
                >
                  {skill}
                </SkillButton>
              ))}
            </SkillsGrid>
          </Section>

          <Section>
            <SectionTitle>경력사항</SectionTitle>
            <Select
              name="experience"
              value={formData.experience}
              onChange={handleInputChange}
            >
              <option value="">경력을 선택하세요</option>
              <option value="신입">신입</option>
              <option value="1-3년">1-3년</option>
              <option value="4-7년">4-7년</option>
              <option value="8년 이상">8년 이상</option>
            </Select>
          </Section>

          <Section>
            <SectionTitle>학력</SectionTitle>
            <Select
              name="education"
              value={formData.education}
              onChange={handleInputChange}
            >
              <option value="">학력을 선택하세요</option>
              <option value="고졸">고졸</option>
              <option value="전문대졸">전문대졸</option>
              <option value="대졸">대졸</option>
              <option value="석사">석사</option>
              <option value="박사">박사</option>
            </Select>
          </Section>

          <Section>
            <SectionTitle>선호 지역</SectionTitle>
            <Select
              name="preferredLocation"
              value={formData.preferredLocation}
              onChange={handleInputChange}
            >
              <option value="">지역을 선택하세요</option>
              <option value="서울">서울</option>
              <option value="경기">경기</option>
              <option value="인천">인천</option>
              <option value="부산">부산</option>
              <option value="대구">대구</option>
              <option value="기타">기타</option>
            </Select>
          </Section>

          <Section>
            <SectionTitle>희망 연봉</SectionTitle>
            <Input
              type="text"
              name="expectedSalary"
              value={formData.expectedSalary}
              onChange={handleInputChange}
              placeholder="희망 연봉을 입력하세요 (예: 4000)"
            />
          </Section>

          <SubmitButton type="submit" disabled>
            매칭 시작하기
          </SubmitButton>
        </Form>
      )}

      {step === 2 && (
        <ResultsContainer>
          <h3>매칭 결과</h3>
          {/* TODO: 매칭 결과 표시 */}
          <p>분석 중입니다...</p>
        </ResultsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #2E74FF;
  text-align: center;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  color: #333;
  margin-bottom: 1rem;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const SkillButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.selected ? '#2E74FF' : '#ddd'};
  border-radius: 20px;
  background: ${props => props.selected ? '#2E74FF' : 'white'};
  color: ${props => props.selected ? 'white' : '#333'};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.selected ? '#1b5cd9' : '#f8f9fa'};
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #2E74FF;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: ${props => props.disabled ? '#cccccc' : '#2E74FF'};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.disabled ? '#cccccc' : '#1E64EF'};
  }
`;

const ResultsContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const ComingSoonBanner = styled.div`
  background: linear-gradient(135deg, #2E74FF 0%, #4B8BFF 100%);
  color: white;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  span {
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    margin: 0;
    opacity: 0.9;
  }
`;

export default JobMatchingPage;
