import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import { FaComments, FaMicrophone } from 'react-icons/fa';

const SavedInterviews = () => {
  const [interviews, setInterviews] = useState({
    text: [],
    voice: []
  });
  const [activeTab, setActiveTab] = useState('text');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchInterviews();
  }, []);

  const fetchInterviews = async () => {
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/interview/history`,
        {
          headers: { 'x-auth-token': token }
        }
      );

      if (response.data.success) {
        // 면접 유형에 따라 데이터 분류
        const textInterviews = response.data.interviews.filter(
          interview => interview.interviewType === 'text'
        );
        const voiceInterviews = response.data.interviews.filter(
          interview => interview.interviewType === 'voice'
        );

        setInterviews({
          text: textInterviews,
          voice: voiceInterviews
        });
        setError(null);
      }
    } catch (error) {
      console.error('면접 기록 조회 오류:', error);
      setError('면접 기록을 불러오는데 실패했습니다.');
      setInterviews({ text: [], voice: [] });
    }
  };

  return (
    <Container>
      <Header>
        <Title>AI 면접 기록</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <TabContainer>
          <Tab 
            active={activeTab === 'text'} 
            onClick={() => setActiveTab('text')}
          >
            <FaComments /> 텍스트 면접
          </Tab>
          <Tab 
            active={activeTab === 'voice'} 
            onClick={() => setActiveTab('voice')}
          >
            <FaMicrophone /> 음성 면접
          </Tab>
        </TabContainer>
      </Header>

      <InterviewList>
        {interviews[activeTab].length > 0 ? (
          interviews[activeTab].map((interview) => (
            <InterviewCard key={interview._id}>
              <InterviewInfo>
                <InterviewDate>
                  {new Date(interview.createdAt).toLocaleDateString()}
                </InterviewDate>
                <InterviewType>
                  {interview.jobField} - {interview.interviewType === 'text' ? '텍스트' : '음성'} 면접
                </InterviewType>
              </InterviewInfo>
              <ScoreSection>
                <ScoreItem>
                  <ScoreLabel>종합 평가</ScoreLabel>
                  <ScoreValue>{interview.overallScore}점</ScoreValue>
                </ScoreItem>
                <ScoreItem>
                  <ScoreLabel>기술력</ScoreLabel>
                  <ScoreValue>{interview.technicalScore}점</ScoreValue>
                </ScoreItem>
                <ScoreItem>
                  <ScoreLabel>의사소통</ScoreLabel>
                  <ScoreValue>{interview.communicationScore}점</ScoreValue>
                </ScoreItem>
              </ScoreSection>
              <ViewButton href={`/interview/result/${interview._id}`}>
                상세 결과 보기
              </ViewButton>
            </InterviewCard>
          ))
        ) : (
          <EmptyMessage>
            {activeTab === 'text' ? '텍스트' : '음성'} 면접 기록이 없습니다.
          </EmptyMessage>
        )}
      </InterviewList>
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Tab = styled.button`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  background-color: ${props => props.active ? '#2E74FF' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : '#666'};
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#2361DB' : '#e0e0e0'};
  }
`;

const InterviewList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const InterviewCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const InterviewInfo = styled.div`
  margin-bottom: 1rem;
`;

const InterviewDate = styled.div`
  color: #666;
  font-size: 0.9rem;
`;

const InterviewType = styled.div`
  font-weight: 500;
  color: #333;
  margin-top: 0.5rem;
`;

const ScoreSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

const ScoreItem = styled.div`
  text-align: center;
`;

const ScoreLabel = styled.div`
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.3rem;
`;

const ScoreValue = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #2E74FF;
`;

const ViewButton = styled.a`
  display: block;
  text-align: center;
  padding: 0.8rem;
  background-color: #2E74FF;
  color: white;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2361DB;
  }
`;

const EmptyMessage = styled.div`
  text-align: center;
  color: #666;
  padding: 2rem;
  grid-column: 1 / -1;
`;

const ErrorMessage = styled.div`
  color: #e74c3c;
  background-color: #fdeaea;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  text-align: center;
  font-weight: 500;
`;

export default SavedInterviews;
