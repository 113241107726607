import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SuccessPage.css';
import { CheckCircle } from 'lucide-react';

const PaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    price,
    planTitle,
    tokens,
    features,
    today,
    nextPaymentDate,
  } = location.state || {};

  return (
    <div className="payment-success-container">
      <div className="success-header">
        <CheckCircle size={48} color="#0070f3" />
        <h2>결제가 완료되었습니다</h2>
      </div>

      <div className="plan-summary-box">
        <div className="plan-info">
          <span className="plan-title">{planTitle || 'Professional plan'}</span>
          <p className="plan-price">{price ? `${price.toLocaleString()}원 / 월` : '정보 없음'}</p>
          <p className="plan-tokens">{tokens ? `${tokens.toLocaleString()} tokens` : '토큰 정보 없음'}</p>
        </div>
        <ul className="features-list">
          {features && features.length > 0 ? (
            features.map((feature, index) => (
              <li key={index}>
                <CheckCircle size={16} color="#4CAF50" />
                {feature}
              </li>
            ))
          ) : (
            <li>플랜 설명이 없습니다.</li>
          )}
        </ul>
      </div>

      <div className="payment-details">
        <div className="detail-item">
          <span>결제 금액</span>
          <span>{price ? `${price.toLocaleString()}원` : '정보 없음'}</span>
        </div>
        <div className="detail-item">
          <span>결제일</span>
          <span>{today || '정보 없음'}</span>
        </div>
        <div className="detail-item">
          <span>다음 결제일</span>
          <span>{nextPaymentDate || '정보 없음'}</span>
        </div>
      </div>

      <div className="action-buttons">
        <button className="main-button" onClick={() => navigate('/main')}>
          메인화면
        </button>
        <button className="tokens-button" onClick={() => navigate('/mypage/profile')}>
          마이페이지
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;