import React, { useState } from 'react';
import './OptionsStep.css';
import standard from '../../../assets/images/photosgenerator/standard.png';
import office from '../../../assets/images/photosgenerator/office.png';
import warmoffice from '../../../assets/images/photosgenerator/warmoffice.png';
import natural from '../../../assets/images/photosgenerator/natural.png';

const photoOptions = [
  {
    style: 'standard',
    label: '증명사진',
    src: standard,
    description: '취업, 입사, 비자 등 공식 용도로 사용 가능한 기본 증명사진'
  },
  {
    style: 'office',
    label: '프로필',
    src: office,
    description: '소셜 미디어, 이력서 등에 사용할 수 있는 전문적인 프로필 사진'
  },
  {
    style: 'warmoffice',
    label: '따뜻한 분위기',
    src: warmoffice,
    description: '따뜻하고 친근한 분위기의 전문가 이미지'
  },
  {
    style: 'natural',
    label: '자연스러운',
    src: natural,
    description: '자연스럽고 편안한 분위기의 사진'
  }
];

const OptionsStep = ({ style, setStyle, handleNextStep }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedStyle, setSelectedStyle] = useState(style);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      Math.min(prevIndex + 1, photoOptions.length - 1)
    );
  };

  const handleStyleSelect = (selectedStyle) => {
    setStyle(selectedStyle);
    setSelectedStyle(selectedStyle);
  };

  return (
    <div className="options-step">
      <h1 className="options-step__title">증명사진 생성기</h1>
      <h2 className="options-step__subtitle">스타일 선택 (1/2 Step)</h2>
      
      <div className="options-step__coming-soon">
        <span>🚀 COMING SOON</span>
        <p>더 나은 서비스 제공을 위해 준비 중입니다</p>
      </div>

      <div className="options-step__style-grid">
        <button 
          className="options-step__nav-button" 
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          ←
        </button>
        
        <div 
          className="options-step__slider"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`
          }}
        >
          {photoOptions.map((option, index) => (
            <div
              key={index}
              className={`options-step__style-option ${
                selectedStyle === option.style ? 'selected' : ''
              }`}
              onClick={() => handleStyleSelect(option.style)}
            >
              <div className="options-step__style-image">
                <img src={option.src} alt={option.label} />
                <div className="options-step__style-info">
                  <h3>{option.label}</h3>
                  <p>{option.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button 
          className="options-step__nav-button" 
          onClick={handleNext}
          disabled={currentIndex === photoOptions.length - 1}
        >
          →
        </button>
      </div>

      <div className="step-buttons">
        <button 
          className="step-button step-button--primary"
          onClick={handleNextStep}
          disabled={true}
        >
          다음
        </button>
      </div>
    </div>
  );
};

// CSS 스타일 추가
const styles = `
.options-step__coming-soon {
  background: linear-gradient(135deg, #2E74FF 0%, #4B8BFF 100%);
  color: white;
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.options-step__coming-soon span {
  font-size: 1.5rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.options-step__coming-soon p {
  font-size: 1rem;
  margin: 0;
  opacity: 0.9;
}
`;

// 스타일 태그 생성 및 추가
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default OptionsStep;