import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FailPage.css';
import { XCircle } from 'lucide-react';

const PaymentFailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // location.state에서 에러 정보를 추출
  const { errorMessage, paymentId } = location.state || {};

  return (
    <div className="payment-fail-container">
      <div className="fail-header">
        <XCircle size={48} color="#FF0000" />
        <h2>결제가 정상적으로 완료되지 않았습니다.</h2>
        <p>불편을 드려 죄송합니다. 다른 결제 수단을 사용하거나 나중에 다시 시도해 주십시오.</p>
      </div>

      <div className="error-details-box">
        <div className="error-item">
          <span>사유</span>
          <span className="error-message">{errorMessage || '알 수 없는 오류입니다.'}</span>
        </div>
        <div className="error-item">
          <span>결제번호</span>
          <span className="payment-id">{paymentId || '정보 없음'}</span>
        </div>
      </div>

      <div className="action-buttons">
        <button className="retry-button" onClick={() => navigate('/payment')}>
          다시 결제하러 가기
        </button>
        <button className="main-button" onClick={() => navigate('/main')}>
          메인화면
        </button>
      </div>
    </div>
  );
};

export default PaymentFailPage;