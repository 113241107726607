import React from 'react';
import starLogo from '../assets/images/logos/starlogo.png';
import './SpinningLogo.css';

const SpinningLogo = ({ text }) => {
    return (
        <div className="spinning-logo-container">
            <img src={starLogo} alt="Loading" className="spinning-logo" />
            {text && <p className="loading-text">{text}</p>}
            <div className="loading-indicator">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
            </div>
            <p className="warning-text">
                생성중입니다... <br />
                새로고침이나 페이지 이동 시 생성이 취소되고<br />
                토큰이 차감됩니다.
            </p>
        </div>
    );
};

export default SpinningLogo; 